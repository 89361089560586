import request from '@/utils/request'

// 漫画首页
export function comics_home(data) {
  return request({
    url: '/api/app/comics/home',
    method: 'post',
    data
  })
}

// 动漫首页
export function media_home(data) {
  return request({
    url: '/api/app/media/homev3',
    method: 'post',
    data
  })
}

// 有声小说首页
export function novel_home(data) {
  return request({
    url: '/api/app/novel/homev2',
    method: 'post',
    data
  })
}

// 剧场首页
export function dream_home(data) {
  return request({
    url: '/api/app/media/drama/home',
    method: 'post',
    data
  })
}

// 漫画主题
export function comicsTopic(data) {
  return request({
    url: '/api/app/comicsTopic/list',
    method: 'post',
    data
  })
}
// 小说主题
export function novelTopic(data) {
  return request({
    url: '/api/app/novelTopic/list',
    method: 'post',
    data
  })
}
// 动漫主题
export function mediaTopic(data) {
  return request({
    url: '/api/app/media/topic/details',
    method: 'post',
    data
  })
}
// 漫画详情
export function comicsDetail(data) {
  return request({
    url: '/api/app/comics/detail',
    method: 'post',
    data
  })
}
// 漫画收藏 喜欢 /api/app/comics/like
export function comicsLike(data) {
  return request({
    url: '/api/app/comics/like',
    method: 'post',
    data
  })
}
// 小说详情
export function novelDetail(data) {
  return request({
    url: '/api/app/novel/detail',
    method: 'post',
    data
  })
}
// 小说内容
export function novelChapterInfo(data) {
  return request({
    url: '/api/app/novelChapter/info',
    method: 'post',
    data
  })
}
// acg标签
export function filterOption(data) {
  return request({
    url: '/api/app/media/filter/option',
    method: 'post',
    data
  })
}
// 筛选结果接口
export function filterResult(data) {
  return request({
    url: '/api/app/media/filter',
    method: 'post',
    data
  })
}
// 动漫标签详情
export function mediaTagDetails(data) {
  return request({
    url: '/api/app/media/tag/details',
    method: 'post',
    data
  })
}
//小说标签详情
export function novelTagList(data) {
  return request({
    url: '/api/app/novelTag/listByTag',
    method: 'post',
    data
  })
}
//漫画标签详情
export function comicsTagDetails(data) {
  return request({
    url: '/api/app/comicsTag/listById',
    method: 'post',
    data
  })
}
//漫画章节内容
export function comicsChapterPics(data) {
  return request({
    url: '/api/app/comicsChapter/pics',
    method: 'post',
    data
  })
}
//是否能观看漫画章节
export function comicsChapterIsLook(data) {
  return request({
    url: '/api/app/comicsChapter/isLook',
    method: 'post',
    data
  })
}
//是否能观看小说章节
export function novelChapterIsLook(data) {
  return request({
    url: '/api/app/novelChapter/isLook',
    method: 'post',
    data
  })
}
//漫画支付
export function comicsPay(data) {
  return request({
    url: '/api/app/comics/pay', // 漫画支付
    method: 'post',
    data
  })
}
//小说支付
export function novelPay(data) {
  return request({
    url: '/api/app/novel/pay', // 漫画支付
    method: 'post',
    data
  })
}
//漫画标签
export function comicsTag(data) {
  return request({
    url: '/api/app/comicsTag/all', // 漫画支付
    method: 'post',
    data
  })
}
//小说标签
export function novelTag(data) {
  return request({
    url: '/api/app/novelTag/getShowList', // 漫画支付
    method: 'post',
    data
  })
}
// 漫画标签列表
export function comicsTagById(data) {
  return request({
    url: '/api/app/comicsTag/listById', // 漫画支付
    method: 'post',
    data
  })
}
// 小说标签列表
export function novelTagTagById(data) {
  return request({
    url: '/api/app/novelTag/listByTag', // 漫画支付
    method: 'post',
    data
  })
}
// 主题投票 /api/app/media/topic/poll/vote
export function topicPoll(data) {
  return request({
    url: '/api/app/media/topic/poll/vote', // 漫画支付
    method: 'post',
    data
  })
}
