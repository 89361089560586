let BASEURL

// const apiType = 'h5app'
const apiType = 'app'
apiType === 'h5app' ? (BASEURL = '/api/h5app') : (BASEURL = '/api/app')
const api = {
  // 系统配置板块======================
  config: BASEURL + '/ping/config', // 获取配置
  collect: BASEURL + '/collect/add', // 统一收藏接口
  paytypeinfo: BASEURL + '/gold/paytypeinfo', // 支付列表及信息 /api/app/gold/paytypeinfo
  uploadImgs: BASEURL + '/upload/images', // 多图上传  /api/app/upload/images
  uploadOneImg: BASEURL + '/upload/img', // 多图上传  /api/app/upload/img
  uploadVideo: BASEURL + '/vid/upload', // 视频上传  /vid/upload
  comment_list: BASEURL + '/comment/list', // 评论列表  /api/app/comment/list
  comment_add: BASEURL + '/comment/add', // 发表评论  /api/app/comment/add
  care_add: BASEURL + '/care/add', // 添加关注 /api/app/care/add
  care_cancel: BASEURL + '/care/cancel', // 取消关注 /api/app/care/cancel
  login_captcha: BASEURL + '/login/captcha', // api/app/login/captcha 获取验证码
  bind_Phone: BASEURL + '/login/bindMobile', // /api/app/login/bindMobile  绑定手机
  kf: BASEURL + '/custom/getEndpoint', // /custom/getEndpoint
  phone_login: BASEURL + '/login/phone', // 手机号登录/api/app/login/phone
  official_login: BASEURL + '/login/official', // 正式登录 /api/app/login/official
  account_login: BASEURL + '/login/account/login', // 账号登录 /api/app/login/official
  apps_list: BASEURL + '/apps/list', // 应用中心 /api/app/apps/list
  checkin_list: BASEURL + '/checkin/list', // 签到记录列表  /api/app/checkin/list
  checkin_click: BASEURL + '/checkin/click', // 用户签到  /api/app/checkin/click
  checkin_setup: BASEURL + '/checkin/setup', // 签到规则 /api/app/checkin/setup

  // 首页视频板块
  index_home: BASEURL + '/media/homev2', // 首页数据
  media_details: BASEURL + '/media/topic/details', // 常见问题列表
  media_actor_info: BASEURL + '/media/actor/info', // 女优详情信息列表
  media_actor_details: BASEURL + '/media/actor/details', // 女优详情信息列表

  // 搜索
  search_hot: BASEURL + '/hotsearch/list', // 热门搜索
  search_hot2: BASEURL + '/hotsearch/list2', // 热词列表V2
  hot_changeOne: BASEURL + '/hotsearch/changeOne', // 换一换
  search: BASEURL + '/search/detailsV2', // 搜索  search/detailsV2

  tag_list: BASEURL + '/media/tag/list', // 标签
  hot_tag_list: BASEURL + '/media/hot/tag/list', // 热门标签
  tag_category: BASEURL + '/media/category', // 搜索 片库
  tag_detail: BASEURL + '/media/tag/details', // 标签详情
  media_search_home: BASEURL + '/media/search/home', // 搜索 片库
  search_tag_detail: BASEURL + '/search/tag/detail', // 搜索 标签视频
  acg_category: BASEURL + '/media/acg/category', //ACG片库

  // 活动 /api/app/activity/list /api/app/activity/detail /api/app/advertise/click
  activity_list: BASEURL + '/activity/list', // 活动列表
  activity_detail: BASEURL + '/activity/detail', // 活动详情
  advertise_click: BASEURL + '/mission/getReward', // 点击获取活动奖励 /api/app/mission/getReward
  xs_activity_list: BASEURL + '/mission/info', // 新手活动列表 /api/app/mission/info

  // 用户板块==================================
  ykInfo: BASEURL + '/login/guest', // 游客信息
  userInfo: BASEURL + '/user/info', // 用户信息
  userQrcode: BASEURL + '/user/qrcode', // 账号凭证
  transList: BASEURL + '/trans/list', // 交易记录 /api/app/trans/list
  update_info: BASEURL + '/user/info/modify', // /api/app/user/info/modify 修改用户信息
  user_avatar: BASEURL + '/user/avatar', // 默认头像/api/app/user/avatar
  care_list: BASEURL + '/care/list', // 关注列表 /api/app/care/list
  care_fans: BASEURL + '/care/fans', // 粉丝列表 /api/app/care/fans
  redeemcode_list: BASEURL + '/redeemcode/list', // 兑换记录 /api/app/redeemcode/list
  redeemcode_user: BASEURL + '/redeemcode/use', // 使用兑换码 /api/app/redeemcode/use /api/app/message/list
  message_list: BASEURL + '/message/list', // 获取消息列表 /api/app/message/list
  message_dialog: BASEURL + '/message/dialog', // 获取私信列表   /api/app/message/dialog
  withdrawal_info: BASEURL + '/withdrawal/info', // /api/app/withdrawal/info
  withdrawal_submit: BASEURL + '/withdrawal/submit', // 申请提现 /api/app/withdrawal/submit
  user_income: BASEURL + '/user/invite/income', // 我的邀请信息 api/app/user/invite/income
  income_detail: BASEURL + '/trans/incomes', // 我的收益明细 api/app/user/invite/income
  config_contact: BASEURL + '/config/contact', // 交流群 /api/app/config/contact
  publish_list: BASEURL + '/publish/list', // 我的发布列表 /api/app/publish/list
  // publish_listV2: BASEURL + '/publish/listV2', // 我的发布列表 /api/app/publish/list
  publish_del: BASEURL + '/publish/del', // 删除发布信息 /api/app/publish/del
  collect_list: BASEURL + '/collect/list', // 我的收藏列表 /api/app/collect/list
  collect_del: BASEURL + '/collect/del', // 批量删除我的收藏 /api/app/collect/del
  pay_history: BASEURL + '/media/pay/history', // 我的购买列表 /api/app/media/pay/history
  msg_info: BASEURL + '/message/info', // 获取私聊信息 /api/app/message/info
  msg_send: BASEURL + '/message/send', // 发送私信 /api/app/message/send
  user_qrcode_info: BASEURL + '/user/qrcode/info', // 二维码找回账户信息   /api/app/user/qrcode/info

  // 约炮板块=======================
  dating_bosses: BASEURL + '/dating/boss/recoment', // 商家列表
  dating_category: BASEURL + '/dating/category', // 约炮分类详情 type 1楼凤 2上门 3商家
  bosses_detail: BASEURL + '/dating/boss/detail', // 获取经纪人详情详情
  city_list: BASEURL + '/dating/city', // 获取经纪人详情详情
  report_publish: BASEURL + '/dating/report/publish', // 发布体验报告/api/app/dating/report/publish
  dating_report: BASEURL + '/dating/report', // 获取验车报告
  dating_publish: BASEURL + '/dating/publish', // 发布上门消息报告/api/app/dating/report/publish
  dating_service: BASEURL + '/dating/service', // 服务列表 /api/app/dating/service
  dating_pay: BASEURL + '/dating/pay', // 楼凤支付 /api/app/dating/pay
  dating_complaint: BASEURL + '/dating/complaint', // 楼凤举报 /api/app/dating/complaint /api/app/dating/loufeng/detail
  loufeng_detail: BASEURL + '/dating/loufeng/detail', // 楼凤详情 /api/app/dating/loufeng/detail
  model_detail: BASEURL + '/dating/model/detail', // 上门详情 /api/app/dating/model/detail
  dating_config: BASEURL + '/dating/config', // 上门配置  /api/app/dating/config
  loufeng_type: BASEURL + '/dating/loufeng/type', // 上门配置  /api/app/dating/config
  dating_collect: BASEURL + '/dating/collect', // 楼风收藏    /api/app/dating/collect

  // 视频播放页相关接口
  video_play: BASEURL + '/media/play', // 视频播放 /api/app/media/play
  video_pay: BASEURL + '/media/pay', // 视频付费 /api/app/media/pay
  video_tag: BASEURL + '/media/tag', // 视频标签 /api/app/media/tag
  video_like: BASEURL + '/playpage/like', // 猜你喜欢
  play_play_list: BASEURL + '/playpage/playlist', // 相关播单
  play_actor_list: BASEURL + '/playpage/actor', // 推荐女优

  // 社区
  post_list: BASEURL + '/post/list', // 帖子列表 /api/app/post/list
  // community_detail: BASEURL + '/post/detail', // 帖子详情 /api/app/post/detail（旧）
  post_pay: BASEURL + '/post/pay', // 帖子购买 /api/app/post/pay
  community_publish: BASEURL + '/post/publish', // 帖子发布 /api/app/post/publish
  community_findhot: BASEURL + '/findhot/getfindhot', // 热搜榜 /api/app/findhot/getfindhot
  user_publish: BASEURL + '/dating/publish/history', // 我的楼风发布列表 /api/app/dating/publish/history
  user_post_publish: BASEURL + '/post/publish/list', // 我的帖子发布列表 /api/app/post/publish/list
  user_media: BASEURL + '/media/user', // 小视频列表详情 /api/app/media/user
  community_home: BASEURL + '/post/list', // 发现页home页 /api/app/findhot/home（新）
  // community_pay: BASEURL + '/findhot/pay', // 帖子购买 /api/app/findhot/pay（新）
  // community_publish: BASEURL + '/findhot/publish', // 帖子发布 /api/app/findhot/publish（新）
  community_detail: BASEURL + '/post/detail', // 帖子详情 /api/app/findhot/detail（新）

  community_search: BASEURL + '/findhot/getfindhot', // 帖子详情 /api/app/findhot/detail（新）
  // 会员卡
  vip_list: BASEURL + '/card/list', // /api/app/card/listi/app/recharge/submit
  recharge_sumbit: BASEURL + '/recharge/submit', // /api/app/recharge/submit 会员卡充值

  // 合集购买
  gather_buy: BASEURL + '/media/gather/pay', // /api/app/recharge/submit 会员卡充值
  // 会员中心相关
  getLotteryList: BASEURL + '/prize/list', // /api/app/prize/list 获取列表

  getLotteryRedeem: BASEURL + '/prize/redeem', // /api/app/prize/list 获取列表
  getLotteryRedeemHistory: BASEURL + '/prize/redeemhistory', // /api/app/prize/list 获取兑换中心历史

  // 抽奖中心相关
  checkLottery: BASEURL + '/lottery/lottery', // /api/app/lottery/list 抽奖中心抽奖
  getLotteryListAll: BASEURL + '/lottery/list', // /api/app/prize/list 获取抽奖中心列表
  lotteryHistory: BASEURL + '/lottery/histories', // /lottery/histories 获取抽奖中心历史
  recharge_list: BASEURL + '/trans/recharge/list', //  /api/app/trans/recharge/list 充值记录
  // 提现记录 /withdrawal/list
  withdrawal_list: BASEURL + '/withdrawal/list', //  /api/app/trans/withdrawal/list 充值记录
  // 签到

  feedback_add: BASEURL + '/feedback/add', // 反馈

  // 帖子标签列表
  section_list: BASEURL + '/post/section/list', // 反馈  /api/app/post/section/list
  // 获取代理配置-分享邀请
  proxy_config: BASEURL + '/proxy/config', // 反馈  /api/app/post/section/list
  // 注册
  account_register_api: BASEURL + '/login/account/register',

  // ai
  aitemplate_list: BASEURL + '/aitemplate/list', //ai模版列表
  aiorder_strip_submit: BASEURL + '/aiorder/strip/submit', //ai脱衣提交
  aiorder_strip_list: BASEURL + '/aiorder/strip/list', //  ai脱衣订单查询
  aiorder_facechange_submit: BASEURL + '/aiorder/facechange/submit', //ai换脸订单提交
  aiorder_facechange_list: BASEURL + '/aiorder/facechange/list', //ai换脸查询
  aitemplate_list_multisrip: BASEURL + '/aitemplate/multistrip/list',

  // 签到信息获取
  checkin_page: BASEURL + '/checkin/page',
  prize_redeem: BASEURL + '/prize/redeem',
  prize_redeemhistory: BASEURL + '/prize/redeemhistory',

  appsClick: BASEURL + '/apps/click',
  // 保存账号凭证之前调用
  userIsCert: BASEURL + '/user/isCert',
  // 广告统计接口 /api/app/advertise/click
  advertiseClick: BASEURL + '/advertise/click',

  //播单
  myPlayList: BASEURL + '/playlist/mypage', // 我的播单
  sharePlayList: BASEURL + '/playlist/share', // 我的播单
  creatPlayList: BASEURL + '/playlist/create', // 新建播单,
  playlist_page: BASEURL + '/playlist/page', // 新建播单,
  playlist_topicinfo: BASEURL + '/playlist/topicinfo', // 新建播单,
  playlist_detail_list: BASEURL + '/playlist/detaillist', // 播单详情列表,
  playlist_topicList: BASEURL + '/playlist/topicList', // 播单主题列表,

  //发现模块和排行榜
  ranking_list: BASEURL + '/haoserank/list', //排行榜
  // discover_index: BASEURL + '/category/topic', //发现首页
  actor_search: BASEURL + '/media/actor/search', //演员筛选
  actor_search_tag: BASEURL + '/media/actor/searchTagList', //演员作品筛选标签
  topic_detail: BASEURL + '/findpage/topicDetail', //发现页主题更多滑动
  findpage_topic: BASEURL + '/findpage/topic', //发现页主题列表

  //套图站
  photo_station: BASEURL + '/photo/station', //套图站列表
  photo_station_tags: BASEURL + '/photo/station/tags', //套图站标签
  photo_station_detail: BASEURL + '/photo/detail', //套图站详情接口
  // 美图主题
  photo_topic: BASEURL + '/photo/topic', //套图站标签
  photo_topic_list: BASEURL + '/photo/topic/list', //套图站标签

  // 获取对话信息 /api/h5app/message/info
  GET_SESSION_MESSAGE: `${BASEURL}/message/info`,
  // 发送消息 /api/h5app/message/send
  SEND_MESSAGE: `${BASEURL}/message/send`,
  // 私信对话列表
  MESSAGE_DIALOG: `${BASEURL}/message/dialog`,

  // 获取浏览记录列表
  get_history_list: BASEURL + '/watch/list',
  // 删除浏览记录
  del_history: BASEURL + '/watch/delWatch',
  discuss_category: BASEURL + '/works/discuss/category',
  select_discuss: BASEURL + '/works/select/discuss',
  discuss_content: BASEURL + '/works/discuss/content',
  discuss_top: BASEURL + '/works/discuss/top',
  // 常见问题/aicustomerservice/list
  aicustomerserviceList: BASEURL + '/faq/list',
}

export default api
