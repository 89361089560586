const state = {
    showGestureLock: false,
    openGestureLock: window.localStorage.getItem('hs_lockScreen') === 'true' ? true : false, //是否开启密码锁
    gestureLockInfo: {},
    lockStatus: false
}
const getters = {
    getShowGestureLock(state) {
        return state.showGestureLock
    },
    getOpenGestureLock(state) {
        return state.openGestureLock
    },
    getGestureLockInfo(state) {
        return state.gestureLockInfo
    },
    getLockStatus(state) {
        return state.lockStatus
    }
}
const mutations = {
    SET_SHOWGESTURELOCK(state, data) {
        state.showGestureLock = data
    },
    SET_OPENGESTURELOCK(state, data) {
        state.openGestureLock = data
        window.localStorage.setItem('hs_lockScreen', data)
    },
    SET_GESTURELOCKINFO(state, data) {
        state.gestureLockInfo = data
    },
    CHANGELOCKSTATUS(state, data) {
        state.lockStatus = data
    }
}
const actions = {
    setShowGestureLock({ commit }, data) {
        commit('SET_SHOWGESTURELOCK', data)
    },
    setOpenGestureLock({ commit }, data) {
        commit('SET_OPENGESTURELOCK', data)
    },
    setGestureLockInfo({ commit }, data) {
        commit('SET_GESTURELOCKINFO', data)
    },
    changeLockStatus({ commit }, data) {
        commit('CHANGELOCKSTATUS', data)
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
