/*
 * @Author:
 * @Mail:
 * @Date: 2024-02-02 11:47:27
 * @LastEditTime: 2024-02-07 16:58:15
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @FilePath: /haoseH5/src/plugins/component.js
 */
/*
 * @Author:
 * @Mail:
 * @Date: 2023-06-29 19:59:22
 * @LastEditTime: 2023-12-29 19:27:24
 * @LastEditors: Please set LastEditors
 * @FilePath: /bliblih5/plugins/component.js
 */
import Vue from 'vue'

import {
  EFootBar,
  EBackTop,
  EDecryptImg,
  EDecryptImgNolazy,
  EADSwiper,
  EGenerateImg,
  ETab,
  ERefreshLoad,
  EJumpLinkPage,
  EAddPwa,
  ERefreshLoadV3,
  EHomeSwiper,
  ERefreshingPage,
  ETabV2,
  ETabBar,
  EWaterFall,
  EWaterFallH,
  EVideoCover,
  ESCVideoCover
} from '@/components/web_base/component_base/index.js'

const components = [
  EFootBar,
  EBackTop,
  EDecryptImg,
  EDecryptImgNolazy,
  EADSwiper,
  EGenerateImg,
  ETab,
  ERefreshLoad,
  EJumpLinkPage,
  EAddPwa,
  ERefreshLoadV3,
  EHomeSwiper,
  ERefreshingPage,
  ETabV2,
  ETabBar,
  EWaterFall,
  EWaterFallH,
  EVideoCover,
  ESCVideoCover
]

export default ({ app, $axios, store, route, redirect }) => {
  const imgCDN = new Object({
    typeof: String,
    default() {
      return store.getters['cdn']
    }
  })
  const DefaultUrl = new Object({
    typeof: String,
    default() {
      return require('~/static/loading.png')
    }
  })

  components.forEach(component => {
    if (component.name === 'EDecryptImg') {
      //   component.props.defaultUrl = DefaultUrl
    }
    if (component.props && component.props.imgCDN) {
      component.props.imgCDN = imgCDN
    }
    Vue.component(component.name, component)
  })
}
