import { userInfo } from '@/api/user.js'
const state = {
  // auth
  token: '',
  // 用户信息
  info: {
    id: 15783,
    cardType: 0,
    userType: 1,
    balance: 0,
    recharge: 0,
    income: 0,
    region: '',
    userBackgroud: '',
    actressBackgroud: '',
    avatarUrl: 'image/1uy/22p/2p2/2aw/fcdae3c859afc6ed0135842a43f7de8e.png',
    nickName: '奎培',
    introduction: '',
    lastLoginAt: 1706689305,
    vipExpire: -62135596800,
    vipExpireTime: '0001-01-01T00:00:00Z',
    vipType: 0,
    cardName: '',
    mobile: '',
    country: '',
    age: 0,
    gender: 0,
    invites: 0,
    inviteCode: '79ZZ6EVH',
    dicCodePromSeqe: '',
    inviteUrl: 'https://llhj.tv?pc=79ZZ6EVH',
    leftWatchTimes: 3,
    totalWatchTimes: 3,
    movieTickets: 0,
    token: '',
    loginType: 0,
    districtCode: '',
    leftDownloadTimes: 0,
    totalDownloadTimes: 0,
    newMsg: false,
    rights: null,
    vipImage: '',
    avatarFrame: '',
    cardAllNum: 0,
    cardTopicNum: 0,
    logo: '',
    careCount: 0,
    fans: 0,
    downLoadTotal: 0,
    promotionExpiredAt: '2024-02-01T08:21:44.683Z',
    points: 0,
    giftGold: 0,
    lastSystemMsg: '测试',
    updatedAt: '2023-12-02T04:32:00.97Z',
    newSystemMsg: true
  },

  // 游客uuid
  uid: '',
  showAccountImg: true,
  isSaveImg: false,
  isLight: null,
  isFirstLogin: true,
  firstCheckAn: true,
  firstCheckDeep: true,
  firstCheckDark: true
}
// VipType_vtnone     VipType = 0 + iota // 普通用戶0
//   VipType_experience                    // 体验卡1
//   VipType_day                           // 天卡2
//   VipType_week                          // 周卡3
//   VipType_month                         // 月卡4
//   VipType_quarterly                     // 季卡5
//   VipType_year                          // 年卡6
//   VipType_permanent                     // 永久卡7
//   VipType_merchant   VipType = 11       // 商家卡11
//   VipType_super      VipType = 12       // 至尊卡12
const getters = {
  getUid(state) {
    return state.uid
  },
  getToken(state) {
    return state.token
  },
  getUserInfo(state) {
    return state.info
  },
  // 第一次点击暗网
  gerFirstCheckAn(state) {
    return state.firstCheckAn
  },
  // 第一次点击深网
  gerFirstCheckDeep(state) {
    return state.firstCheckDeep
  },
  // 第一次点击浅网
  gerFirstCheckDark(state) {
    return state.firstCheckDark
  },
  // 是否为会员
  isMember(state) {
    if (state.info && state.info.vipType && state.info.vipType >= 1 && state.info.vipExpire > 0) {
      return true
    } else {
      return false
    }
  },
  hasDarkPermission(state) {
    return (
      state.info.vipExpire > 0 && (state.info.vipType === 8 || state.info.vipType === 9 || state.info.vipType === 12)
    )
  },
  // 是否为正式会员
  isZsMember(state) {
    if (state.info && state.info.vipType && state.info.vipType > 1 && state.info.vipExpire > 0) {
      return true
    } else {
      return false
    }
  },
  // 用户观影券数量
  movieTickets(state) {
    return state.info.movieTickets
  },
  userId(state) {
    return state.info.id
  },

  getFirstLogin(state) {
    return state.isFirstLogin
  }
}

const mutations = {
  SET_ACCOUNT_IMG(state, status) {
    state.showAccountImg = status
  },
  // 清除uid
  CLEAR_UID(state) {
    state.uid = ''
  },
  SET_ACCOUNT(state, account) {
    state.info.mobile = account
  },
  SET_PHONE_CODE(state, phoneCode) {
    state.info.country = phoneCode
  },
  SET_FIRSTLOGIN(state, status) {
    state.isFirstLogin = status
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_INFO(state, info) {
    state.info = info
  },
  CLEAR_USER_INFO(state) {
    state.info = {}
  },
  CLEAR_TOKEN(state) {
    state.token = ''
  },
  // 更新uid
  SET_UID(state, uid) {
    state.uid = uid
  },
  // 更新是否保存过个人凭证
  isSaveImg(state, isSaveImg) {
    state.isSaveImg = isSaveImg
  },
  SET_ISLIGHT(state, boolean) {
    state.isLight = boolean
  },
  SET_FIRSTAn(state) {
    state.firstCheckAn = false
  },
  SET_FIRSTDeep(state) {
    state.firstCheckDeep = false
  },
  SET_FIRSTDark(state) {
    state.firstCheckDark = false
  }
}
const actions = {
  setAccount({ commit }, account) {
    commit('SET_ACCOUNT', account)
  },
  setPhoneCode({ commit }, phoneCode) {
    if (phoneCode.includes('+')) {
      const arr = phoneCode.split('+')
      commit('SET_PHONE_CODE', arr[1])
    }
  },
  //更新用户信息
  async get_userInfo({ dispatch }) {
    const res = await userInfo({})
    if (res && res.code === 200) {
      dispatch('setUserInfo', res.data)
    }
  },
  setUserInfo({ commit }, info) {
    commit('SET_INFO', { ...info })
  },
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },
  clearInfo({ commit }) {
    commit('CLEAR_USER_INFO')
    commit('CLEAR_TOKEN')
  },
  setUid({ commit }, uid) {
    commit('SET_UID', uid)
  },
  clearUid({ commit }) {
    commit('CLEAR_UID')
  }
}
export default {
  state,
  mutations,
  actions,
  getters
}
