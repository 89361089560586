const state = {
  config: {},
  //
  // 渠道参数
  channel: null,
  ver: '1.0.2',
  noticeStatus: true,
  hasNewMsg: true, // 是否有新消息

  // 启动页
  hasSplashStatus: true,
  // 弹出广告
  hasSystemADStatus: false,
  announcementPop: false, //首页公告开关
  activityPop: false, // 首页活动开关
  postCategory: [], // 10
  postSection: [] //
}

const getters = {
  hasSplashStatus(state) {
    return state.hasSplashStatus
  },
  hasNewMsg(state) {
    return state.hasNewMsg
  },
  announcementPop(state) {
    return state.announcementPop
  },
  activityPop(state) {
    return state.activityPop
  },
  noticeStatus(state) {
    return state.noticeStatus
  },
  getChannel(state) {
    return state.channel
  },
  config(state) {
    if (state.config) {
      return state.config
    } else {
      return '{}'
    }
  },
  // 活动公告
  announcement(state) {
    const { announcement } = state.config
    return announcement
  },
  // 公告
  runningLight(state) {
    const { runningLight } = state.config
    return runningLight
  },

  // 下载app 图标
  dlAppInfo(state) {
    const { dlAppInfo } = state.config
    return dlAppInfo
  },

  //过滤config的方法
  filterCDN(state, name) {
    const { domain } = state.config
    if (domain) {
      let item = domain.find(item => {
        return item.type === name
      })
      return item && item.urls && item.urls.length ? item.urls[0] : ''
    }
  },

  // 落地页域名
  ldyCdn(state) {
    return getters.filterCDN(state, 'LAND')
  },
  // api域名
  apiUrl(state) {
    return getters.filterCDN(state, 'API')
  },
  // tg联系方式
  tgUrl(state) {
    return getters.filterCDN(state, 'TELEGRAM')
  },
  // 土豆联系方式
  potatoUrl(state) {
    return getters.filterCDN(state, 'POTATO')
  },
  // 不用解密的cdn
  notEncrypCDN(state) {
    return getters.filterCDN(state, 'NOENCRIMAGE')
  },
  // 加密的cdn域名
  cdn(state) {
    return getters.filterCDN(state, 'IMAGE')
  },
  aiImg(state) {
    return getters.filterCDN(state, 'AIIMG')
  },
  shareUrl(state) {
    return getters.filterCDN(state, 'H5SHAREURL')
  },

  //过滤广告
  filterAD(state, position) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter(item => item.position === position)
      return arr
    }
    return []
  },
  iconAds(state) {
    return state.config?.statConfigApp?.list || []
  },
  //九宫格Icon ad
  iconNineListAds(state) {
    return getters.iconAds(state).filter(item => item?.locIds?.includes(1)) || []
  },
  //娱乐Icon ad
  iconRecreationAds(state) {
    console.log(getters.iconAds(state))
    return getters.iconAds(state).filter(item => item?.locIds?.includes(2)) || []
  },
  //首页Icon ad
  iconHomeAds(state) {
    return getters.iconAds(state).filter(item => item?.locIds?.includes(3)) || []
  },
  //AcgIcon ad
  iconAcgAds(state) {
    return getters.iconAds(state).filter(item => item?.locIds?.includes(4)) || []
  },
  //视频播放页Icon ad
  iconPlayListAds(state) {
    return getters.iconAds(state).filter(item => item?.locIds?.includes(5)) || []
  },
  //我的Icon ad
  iconMineAds(state) {
    return getters.iconAds(state).filter(item => item?.locIds?.includes(6)) || []
  },
  // 1获取 启动页广告
  splashAD(state) {
    return getters.filterAD(state, 1)
  },
  // 2获取 首页广告 343*143
  homeAD(state) {
    return getters.filterAD(state, 2)
  },
  // 3获取 播放页广告 345*95
  playAD(state) {
    return getters.filterAD(state, 3)
  },
  // 4获取 我的广告 343*72
  mineAD(state) {
    return getters.filterAD(state, 4)
  },
  // 5获取 弹窗广告 1:1比例
  popAD(state) {
    return getters.filterAD(state, 5)
  },
  // 6获取 发现页广告 686*286
  discoverAD(state) {
    return getters.filterAD(state, 6)
  },
  // 7获取 社区广告 686*286
  commityAD(state) {
    return getters.filterAD(state, 7)
  },
  // 8获取 九宫格头部广告 588*154
  gridTopAD(state) {
    return getters.filterAD(state, 8)
  },
  // 9获取 娱乐广告 678*180
  entertainmentAD(state) {
    return getters.filterAD(state, 9)
  },
  // 10获取 漫画顶部广告 343*143
  comicsTopAd(state) {
    return getters.filterAD(state, 10)
  },
  // 11获取 动漫顶部广告 343*143
  cartoonTopAD(state) {
    return getters.filterAD(state, 11)
  },
  // 12获取 有声小说顶部广告 343*143
  novelTopAD(state) {
    return getters.filterAD(state, 12)
  },
  // 13获取 漫画详情广告 343*95
  cartoonDetailsAD(state) {
    return getters.filterAD(state, 13)
  },
  // 14获取 专题广告 343*95
  topicAD(state) {
    return getters.filterAD(state, 14)
  },

  // 首页
  homecategoryV2(state) {
    const { homecategoryV2 } = state.config
    return homecategoryV2
  },
  // 首页
  homecategoryTag(state) {
    const { avcategory } = state.config
    return avcategory.filter(item => item.showType === 1)
  },
  // up首页tab列表
  homeUpCategoryTab(state) {
    const { upCategory } = state.config
    return upCategory
  },
  // 首页av分类
  avcategoryv2(state) {
    const { avcategoryv2 } = state.config
    return avcategoryv2
  },

  avcategory(state) {
    const { avcategory } = state.config
    return avcategory
  },
  // 首页动漫分类
  cartooncategoryV2(state) {
    const { cartooncategoryV2 } = state.config
    return cartooncategoryV2
  },
  // 首页国产分类
  mdcategoryV2(state) {
    const { mdcategoryV2 } = state.config
    return mdcategoryV2
  },
  // 首页原创分类
  createcategoryV2(state) {
    const { createcategoryV2 } = state.config
    return createcategoryV2
  },
  // 首页小视频分类
  shortcategoryV2(state) {
    const { shortcategoryV2 } = state.config
    return shortcategoryV2
  },

  // 获取短视频列表
  mediaCategory(state) {
    const { mediaCategory } = state.config
    return mediaCategory
  },
  // 获取动漫标签
  cartoonCategory(state) {
    const { cartoonCategory } = state.config
    return cartoonCategory
  },
  // 获取漫画标签
  comicsCategory(state) {
    const { comicsCategory } = state.config
    return comicsCategory
  },
  // 获取首页帖子tab分类
  findPlayListCategory(state) {
    const { playListCategory } = state.config
    return playListCategory
  },
  //获取播单分类
  findCategory(state) {
    const { findCategory } = state.config
    return findCategory
  },
  //剧场分类
  dramaCategory(state) {
    const { dramaCategory } = state.config
    return dramaCategory
  },
  // 获取首页帖子求片ID
  wantToId(state) {
    return null
  },
  // 获取视频播放类别
  payType(state) {
    const { payType } = state.config
    return payType
  },
  // 获取短视频标签
  shortTag(state) {
    const { shortTag } = state.config
    return shortTag
  },
  // 获取活动列表
  notificationInfo(state) {
    const { notificationInfo } = state.config
    return notificationInfo
  },

  // 应用列表
  lotteryAd(state) {
    const { statConfigApp } = state.config
    if (statConfigApp && statConfigApp.nineList && statConfigApp.nineList.length > 0) {
      return statConfigApp.nineList
    }
    return []
  },
  // 热门应用列表福利推荐
  hotList(state) {
    const { statConfigApp } = state.config
    if (statConfigApp && statConfigApp.hotList && statConfigApp.hotList.length > 0) {
      return statConfigApp.hotList
    }
    return []
  },
  // 官方推荐列表福利推荐
  recreationList(state) {
    const { statConfigApp } = state.config
    if (statConfigApp && statConfigApp.recreationList && statConfigApp.recreationList.length > 0) {
      return statConfigApp.recreationList
    }
    return []
  },
  // 娱乐列表
  getRecreationList(state) {
    const { statConfigApp } = state.config
    if (statConfigApp && statConfigApp.recreationList && statConfigApp.recreationList.length > 0) {
      return statConfigApp.recreationList
    }
    return []
  },

  //  帖子分类
  postCategory(state) {
    const { postCategory } = state.config
    if (postCategory && postCategory.length > 0) {
      return [...postCategory]
    }
    return []
  },
  postSection(state) {
    const { postSection } = state.config
    if (postSection && postSection.length > 0) {
      return postSection
    }
    return []
  }
}

const mutations = {
  SET_PARAMS(state, obj) {
    const key = obj.key
    const newChannel = {}
    newChannel[key] = obj.data
    state.channel = {
      ...state.channel,
      ...newChannel
    }
  },
  SET_ANNOUNCEMENT_POP(state, status) {
    state.announcementPop = status
  },
  SET_ACTIVITY_POP(state, status) {
    state.activityPop = status
  },

  SET_CONFIG(state, obj) {
    state.config = obj
  },
  SET_NOTICE_STATUS(state, status) {
    state.noticeStatus = status
  },
  SET_SPLASH_STATUS(state, status) {
    state.hasSplashStatus = status
  },
  SET_SYSTEM_AD_STATUS(state, status) {
    state.hasSystemADStatus = status
  },
  setHasNewMsg(state, status) {
    state.hasNewMsg = status
  }
}
const actions = {
  setParams({ commit }, obj) {
    if (obj && obj.key) {
      commit('SET_PARAMS', obj)
    }
  },
  setConfig({ commit }, obj) {
    commit('SET_CONFIG', obj)
  },

  setNoticeStatus({ commit }, status) {
    commit('SET_NOTICE_STATUS', status)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
