import { IMAGE_DB_CONFIG, IMAGE_DB_KEY, SAVE_COUNT_MAX_KEY } from '../utils_base/EConfig/imgConfig'
//  初始化Image存储对象
let {
  db_name,
  store_name,
  db_version,
  table_name,
  table_key,
  db_option,
  max_save_number
} = IMAGE_DB_CONFIG

InitImageIndexDb(db_name, store_name, db_version).then(res => {
  if (!res) return false
  IMAGE_DB_CONFIG[IMAGE_DB_KEY] = res
})


/**
 * @param {string} DB_name
 * @param {number|number} DB_version
 * @param {string} Base_name
 */
function InitImageIndexDb(DB_name, Base_name, DB_version) {
  return new Promise((resolve, reject) => {
    if (!window.indexedDB) resolve(false)
    const indexedDB = window.indexedDB;
    // console.log(DB_name, Base_name, DB_version);
    const requestDB = indexedDB.open(DB_name, DB_version || 1);
    requestDB.onsuccess = function(event) {
      resolve(event.target.result)
      const response = event.target.result
      // 获取当前数据库存储数量 判断是否进行循环写入
      const transaction = response.transaction(store_name, db_option)
      const objectStore = transaction.objectStore(store_name)
      const index = objectStore.index(table_name)
      const saveCount = index.count()
      saveCount.onsuccess = function(ev) {
        if (saveCount.result && saveCount.result > max_save_number) {
          IMAGE_DB_CONFIG[SAVE_COUNT_MAX_KEY] = true // 存储已经达到上限
        } else {
          IMAGE_DB_CONFIG[SAVE_COUNT_MAX_KEY] = false
        }
      }

    };

    requestDB.onupgradeneeded = function(event) {
      const db = event.target.result
      if (!db.objectStoreNames.contains(Base_name)) {
        const objectStore = db.createObjectStore(Base_name, { autoIncrement: true })
        objectStore.createIndex(table_name, table_key, { unique: true })
      }
    };

    requestDB.onerror = async function(event) {
      reject({
        status: 400,
        tip: '数据库创建失败'
      })
    };
  })
}
