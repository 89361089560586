// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/utils/polyfill' // 去除touch事件谷歌提示
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueBus from 'vue-bus'
import axios from 'axios'
// 全局引入按需引入UI库 vant
import '@/plugins/vant'

// 引入全局样式
import '@/assets/css/index.scss'
// 引入适配代码
import '@/assets/lib/rem.js'

// 引入创建indexDb文件
import '@/components/web_base/plugin_base/indexDB.client'

// filters
import './filters'
// 上传插件
import uploader from 'vue-simple-uploader'
// 复制插件
import VueClipboard from 'vue-clipboard2'

// import { config } from 'api/home';

// import '@/components/web_base/component_base/component.js'
import aaa from '@/plugins/component'
aaa({ App, axios, store })

import SlideVerify from 'vue-monoplasty-slide-verify'
import lottery from 'vue-lottery'
import vdr from 'vue-draggable-resizable-gorkys'

// 导入默认样式
import 'vue-draggable-resizable-gorkys/dist/VueDraggableResizable.css'
Vue.component('Vdr', vdr)
//获取配置后再操作
Vue.use(lottery)
Vue.use(VueClipboard)
Vue.use(uploader)
Vue.use(SlideVerify)
Vue.use(VueBus)
Vue.directive('exchangeHtml', {
  bind: function (el, foo) {
    el.innerHTML += foo.value
  }
})
Vue.config.productionTip = false
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
