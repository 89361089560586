/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    component: () => import('@/views/layouts/index'),
    redirect: '/home',
    meta: {
      title: '首页',
      keepAlive: true
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home/index'),
        meta: { title: '首页', keepAlive: true, hasFooter: true }
      },
      {
        path: '/search',
        name: 'Search',
        component: () => import('@/views/search/index'),
        meta: { title: '搜索', keepAlive: true, hasFooter: false }
      },
      {
        path: '/search/SearchTagVideo',
        name: 'SearchTagVideo',
        component: () => import('@/views/search/SearchTagVideo'),
        meta: { title: '标签视频', keepAlive: true, hasFooter: false }
      },
      {
        path: '/search/result/:value',
        name: 'SearchResult',
        component: () => import('@/views/search/result'),
        meta: { title: '搜索展示', keepAlive: true, hasFooter: false }
      },
      {
        path: '/classType',
        name: 'ClassType',
        component: () => import('@/views/search/homeClassType'),
        meta: { title: '片库分类', keepAlive: true, hasFooter: false }
      },
      {
        path: '/search/Acg',
        name: 'AcgSearch',
        component: () => import('@/views/search/Acg/index'),
        meta: { title: 'Acg搜索', keepAlive: true, hasFooter: false }
      },
      {
        path: '/search/Acg/result/:value',
        name: 'AcgSearchResult',
        component: () => import('@/views/search/Acg/result'),
        meta: { title: 'Acg搜索展示', keepAlive: true, hasFooter: false }
      },
      {
        path: '/Discover/search',
        name: 'DiscoverSearch',
        component: () => import('@/views/search/discSeach'),
        meta: { title: 'Acg搜索', keepAlive: true, hasFooter: false }
      },
      {
        path: '/Acg/classType',
        name: 'AcgClassType',
        component: () => import('@/views/search/acgClassType'),
        meta: { title: 'Acg片库', keepAlive: true, hasFooter: false }
      },
      {
        path: '/acg',
        name: 'Acg',
        component: () => import('@/views/Acg/index'),
        meta: { title: '二次元', keepAlive: true, hasFooter: true }
      },
      {
        path: '/acg/acgTopicDetail',
        name: 'AcgTopicDetail',
        component: () => import('@/views/AcgTopicDetail/index'),
        meta: { title: 'AcgTopicDetail' }
      },
      {
        path: '/home/videoTopicDetail',
        name: 'VideoTopicDetail',
        component: () => import('@/views/videoTopicDetail/index'),
        meta: { title: '主题详情', keepAlive: true }
      },
      {
        path: '/home/playListDetail',
        name: 'PlayListDetail',
        component: () => import('@/views/playListDetail/index'),
        meta: { title: '播单详情', keepAlive: true }
      },
      {
        path: '/home/videoTagDetail',
        name: 'VideoTagDetail',
        component: () => import('@/views/VideoTagDetail/index'),
        meta: { title: '标签详情' }
      },
      {
        path: '/acg/AcgTagsList',
        name: 'AcgTagsList',
        component: () => import('@/views/AcgTagsList/index'),
        meta: { title: 'ACG标签详情' }
      },
      {
        path: '/acg/ComicsReadPage',
        name: 'ComicsReadPage',
        component: () => import('@/views/ComicsReadPage/index'),
        meta: { title: '漫画阅读页' }
      },
      {
        path: '/acg/ComicsDetail',
        name: 'ComicsDetail',
        component: () => import('@/views/ComicsDetail/index'),
        meta: { title: '漫画详情', keepAlive: true }
      },
      {
        path: '/acgFilterSearch',
        name: 'AcgFilterSearch',
        component: () => import('@/views/acgFilterSearch/index'),
        meta: { title: 'Acg筛选' }
      },
      {
        path: '/acg/NovelDetail',
        name: 'NovelDetail',
        component: () => import('@/views/NovelDetail/index'),
        meta: { title: '小说详情' }
      },
      {
        path: '/acg/player',
        name: 'AudioPlayer',
        component: () => import('@/views/Acg/audioPlay'),
        meta: { title: '音频播放' }
      },
      {
        path: '/community',
        name: 'Community',
        component: () => import('@/views/Community/index'),
        meta: { title: '社区', keepAlive: true, hasFooter: true }
      },
      {
        path: '/community/detail/:id',
        name: 'CommunityDetail',
        component: () => import('@/views/Community/detail.vue'),
        meta: { title: '帖子详情', keepAlive: false, hasFooter: false }
      },
      {
        path: '/community/tagDetail/:id',
        name: 'CommunityTagDetail',
        component: () => import('@/views/Community/tagDetail.vue'),
        meta: { title: '', keepAlive: false, hasFooter: false }
      },
      {
        path: '/community/topicDetail/:id',
        name: 'CommunityTopicDetail',
        component: () => import('@/views/Community/topicDetail.vue'),
        meta: { title: '', keepAlive: false, hasFooter: false }
      },
      {
        path: '/community/topicList',
        name: 'CommunityTopicList',
        component: () => import('@/views/Community/topicList.vue'),
        meta: { title: '话题列表', keepAlive: false, hasFooter: false }
      },
      {
        path: '/community/publish',
        name: 'CommunityPublish',
        component: () => import('@/views/Community/publish.vue'),
        meta: { title: '', keepAlive: false, hasFooter: false }
      },
      {
        path: '/community/publishRule',
        name: 'CommunityPublishRule',
        component: () => import('@/views/Community/publishRule.vue'),
        meta: { title: '', keepAlive: false, hasFooter: false }
      },
      {
        path: '/community/info',
        name: 'CommunityInfo',
        component: () => import('@/views/Community/myInfo/index.vue'),
        meta: { title: '', keepAlive: false, hasFooter: false }
      },
      {
        path: '/discover',
        name: 'Discover',
        component: () => import('@/views/Discover/index'),
        meta: { title: '发现', keepAlive: true, hasFooter: true }
      },
      {
        path: '/discover/ranking',
        name: 'Rank',
        component: () => import('@/views/Discover/ranking/index'),
        meta: { title: '排行榜', keepAlive: true }
      },
      {
        path: '/discover/playlist',
        name: 'PlayList',
        component: () => import('@/views/Discover/playList/index'),
        meta: { title: '播单', keepAlive: true }
      },
      {
        path: '/playList/publish',
        name: 'PlayListPublish',
        component: () => import('@/views/playList/publish'),
        meta: { title: '新建播单', keepAlive: true }
      },
      {
        path: '/discover/girl',
        name: 'SearchGirl',
        component: () => import('@/views/Discover/girl/index'),
        meta: { title: '女优', keepAlive: true }
      },

      {
        path: '/discover/photo',
        name: 'PhotoStation',
        component: () => import('@/views/Discover/photo/index'),
        meta: { title: '套图站', keepAlive: false }
      },
      {
        path: '/discover/filter',
        name: 'Filter',
        component: () => import('@/views/Discover/filter/index'),
        meta: { title: '筛选', keepAlive: false }
      },
      {
        path: '/discover/detail',
        name: 'DiscoverDetail',
        component: () => import('@/views/Discover/playList/detail'),
        meta: { title: '播单详情', keepAlive: true }
      },
      {
        path: '/discover/localDetail',
        name: 'DiscoverLocalDetail',
        component: () => import('@/views/Discover/playList/localDetail'),
        meta: { title: '播单详情', keepAlive: false }
      },
      {
        path: '/discover/theme',
        name: 'PhotoTheme',
        component: () => import('@/views/Discover/photo/theme'),
        meta: { title: '美图主题', keepAlive: true }
      },
      {
        path: '/discover/preview',
        name: 'Imgpreview',
        component: () => import('@/views/Discover/photo/preview'),
        meta: { title: '图片预览', keepAlive: false }
      },
      {
        path: '/discover/imgList',
        name: 'ImgList',
        component: () => import('@/views/Discover/photo/imgList'),
        meta: { title: '图片列表', keepAlive: false }
      },
      {
        path: '/discover/actor/:id',
        name: 'DiscoverActor',
        component: () => import('@/views/Discover/actor/index'),
        meta: { title: '演员', keepAlive: true }
      },
      {
        path: '/Mine/myBlank',
        name: 'MineMyBlank',
        component: () => import('@/views/Mine/withdraw/myBlank'),
        meta: { title: '立即提现', noHasHeader: true }
      },
      {
        path: '/Mine/withdraw',
        name: 'MineMyShouyiWithdraw',
        component: () => import('@/views/Mine/withdraw/index'),
        meta: { title: '我的银行卡', noHasHeader: true }
      },
      {
        path: '/Mine',
        name: 'Mine',
        component: () => import('@/views/Mine/index'),
        meta: { title: '我的', keepAlive: false, hasFooter: true }
      },
      {
        path: '/Mine/myCollect',
        name: 'MinemyCollect',
        component: () => import('@/views/Mine/myCollect/index'),
        meta: { title: '我的收藏', keepAlive: false }
      },
      {
        path: '/Mine/myFocus',
        name: 'MinemyFocus',
        component: () => import('@/views/Mine/myFocus/index'),
        meta: { title: '我的关注', keepAlive: true }
      },
      {
        path: '/Mine/myOrder',
        name: 'MinemyOrder',
        component: () => import('@/views/Mine/myOrder/index'),
        meta: { title: '我的订单', keepAlive: false }
      },
      {
        path: '/Mine/myHistory',
        name: 'MinemyHistory',
        component: () => import('@/views/Mine/myHistory/index'),
        meta: { title: '浏览记录', keepAlive: false }
      },
      {
        path: '/Mine/messages',
        name: 'Minemessages',
        component: () => import('@/views/Mine/messages/index'),
        meta: { title: '消息中心', keepAlive: false }
      },
      {
        path: '/Mine/redemptionCode',
        name: 'MineRedemptionCode',
        component: () => import('@/views/Mine/redemptionCode/index'),
        meta: { title: '兑换中心', noHasHeader: true }
      },
      {
        path: '/Mine/myBack',
        name: 'MineMyBack',
        component: () => import('@/views/Mine/myBack/index'),
        meta: { title: '问题反馈', noHasHeader: true }
      },
      {
        path: '/Mine/myQuestion',
        name: 'MineMyQuestionk',
        component: () => import('@/views/Mine/myQuestion/index'),
        meta: { title: '帮助反馈', noHasHeader: true }
      },
      {
        path: '/Mine/cooperateApp',
        name: 'MineCooperateApp',
        component: () => import('@/views/Mine/cooperateApp/index'),
        meta: { title: '应用中心', noHasHeader: true }
      },
      {
        path: '/Mine/setting',
        name: 'MineSetting',
        component: () => import('@/views/Mine/setting/index'),
        meta: { title: '设置中心', noHasHeader: true }
      },
      {
        path: '/Mine/recharge',
        name: 'MineRecharge',
        component: () => import('@/views/Mine/recharge/index'),
        meta: { title: '充值记录', noHasHeader: true }
      },
      {
        path: '/Mine/wihtdrawHistory',
        name: 'MineWihtdrawHistory',
        component: () => import('@/views/Mine/wihtdrawHistory/index'),
        meta: { title: '提现记录', noHasHeader: true }
      },
      {
        path: '/Mine/shareHistory',
        name: 'MineShareHistory',
        component: () => import('@/views/Mine/myShouyi/shareHistory'),
        meta: { title: '分享记录', noHasHeader: true }
      },
      {
        path: '/Mine/inComeDetail',
        name: 'MineInComeDetail',
        component: () => import('@/views/Mine/myShouyi/inComeDetail'),
        meta: { title: '收益明细', noHasHeader: true }
      },
      {
        path: '/Mine/myShare',
        name: 'MineMySharey',
        component: () => import('@/views/Mine/myShouyi/myShare'),
        meta: { title: '分享推广', noHasHeader: true }
      },
      {
        path: '/Mine/transList',
        name: 'MineTransList',
        component: () => import('@/views/Mine/transList/index'),
        meta: { title: '金币明细', noHasHeader: true }
      },
      {
        path: '/Mine/exchangeVip',
        name: 'MineExchangeVip',
        component: () => import('@/views/Mine/exchangeVip/index'),
        meta: { title: '会员兑换', noHasHeader: true }
      },
      {
        path: '/Mine/myShouyi',
        name: 'MineMyShouyi',
        component: () => import('@/views/Mine/myShouyi'),
        meta: { title: '推广福利', noHasHeader: true }
      },
      {
        path: '/Mine/signIn',
        name: 'SignIn',
        component: () => import('@/views/Mine/signIn/index'),
        meta: { title: '签到', noHasHeader: true }
      },
      {
        path: '/Mine/myWallet',
        name: 'MineMyWallet',
        component: () => import('@/views/Mine/myWallet/index'),
        meta: { title: '金币充值', noHasHeader: true }
      },
      {
        path: '/Mine/setting/nickName',
        name: 'MineSettingNickName',
        component: () => import('@/views/Mine/setting/nickName'),
        meta: { title: '修改昵称', noHasHeader: true }
      },
      {
        path: '/Mine/setting/userSign',
        name: 'MineSettingUserSign',
        component: () => import('@/views/Mine/setting/userSign'),
        meta: { title: '个性签名', noHasHeader: true }
      },
      {
        path: '/Mine/setting/avatar',
        name: 'MineSettingAvatar',
        component: () => import('@/views/Mine/setting/avatar'),
        meta: { title: '修改头像', noHasHeader: true }
      },
      {
        path: '/Mine/setting/bindPhone',
        name: 'MineSettingBindPhone',
        component: () => import('@/views/Mine/setting/bindPhone'),
        meta: { title: '绑定手机', noHasHeader: true }
      },
      {
        path: '/Mine/setting/accoutCodeCamera',
        name: 'MineSettingAccoutCodeCamerae',
        component: () => import('@/views/Mine/setting/accoutCodeCamera'),
        meta: { title: '凭证找回账号', noHasHeader: true }
      },
      {
        path: '/Mine/setting/accoutCode',
        name: 'MineSettingAccoutCode',
        component: () => import('@/views/Mine/setting/accoutCode'),
        meta: { title: '账号凭证', noHasHeader: true }
      },
      {
        path: '/Mine/setting/phone',
        name: 'MineSettingPhone',
        component: () => import('@/views/Mine/setting/phone'),
        meta: { title: '手机找回账号', noHasHeader: true }
      },

      {
        path: '/Mine/setting/accout',
        name: 'MineSettingAccout',
        component: () => import('@/views/Mine/setting/accout'),
        meta: { title: '找回账号', noHasHeader: true }
      },
      {
        path: '/Mine/setting/kf',
        name: 'MineSettingKf',
        component: () => import('@/views/Mine/setting/kf'),
        meta: { title: '在线客服', noHasHeader: true }
      },
      {
        path: '/Mine/setting/kfProblem',
        name: 'MineSettingKfProblem',
        component: () => import('@/views/Mine/setting/kfProblem'),
        meta: { title: '常见问题反馈', noHasHeader: true }
      },
      {
        path: '/Mine/login',
        name: 'MineLogin',
        component: () => import('@/views/Mine/login/index'),
        meta: { title: '登陆', noHasHeader: true }
      },
      {
        path: '/Mine/myGroup',
        name: 'MineMyGroup',
        component: () => import('@/views/Mine/myGroup/index'),
        meta: { title: '官方群组', noHasHeader: true }
      },
      {
        path: '/Mine/recruitment',
        name: 'MineRecruitment',
        component: () => import('@/views/Mine/recruitment/index'),
        meta: { title: '官方招募', noHasHeader: true }
      },
      {
        path: '/Mine/register',
        name: 'MineRegister',
        component: () => import('@/views/Mine/register/index'),
        meta: { title: '注册', noHasHeader: true }
      },
      {
        path: '/Mine/vip',
        name: 'MineVip',
        component: () => import('@/views/Mine/vip/index'),
        meta: { title: '会员中心', noHasHeader: true }
      },
      {
        path: '/Mine/myPublish',
        name: 'MineMyPublish',
        component: () => import('@/views/Mine/myPublish/index'),
        meta: { title: '我的发布', noHasHeader: true }
      },
      {
        path: '/ai/facePhoto',
        name: 'facePhoto',
        component: () => import('@/views/ai/facePhoto/facePhoto'),
        meta: { title: 'AI换脸（图片）', keepAlive: false }
      },
      {
        path: '/ai/generate',
        name: 'generate',
        component: () => import('@/views/ai/generate'),
        meta: { title: 'AI生成 ', noHasHeader: true }
      },
      {
        path: '/ai/myGenerate',
        name: 'myGenerate',
        component: () => import('@/views/ai/myGenerate'),
        meta: { title: '我的AI生成 ' }
      },
      {
        path: '/ai/changeFace',
        name: 'changeFace',
        component: () => import('@/views/ai/facePhoto/changeFace'),
        meta: { title: 'AI换脸' }
      },
      {
        path: '/ai/uploadFace',
        name: 'uploadFace',
        component: () => import('@/views/ai/facePhoto/uploadFaceOldBl'),
        meta: { title: 'AI脱衣' }
      },

      // {
      //   path: '/ai/uploadFace',
      //   name: 'uploadFace',
      //   component: () => import('@/views/ai/facePhoto/newUploadFace'),
      //   meta: { title: 'AI脱衣' }
      // },
      {
        path: '/play/longVideo/:id',
        name: 'PlayLongVideo',
        component: () => import('@/views/play/longVideo/index'),
        meta: { title: '长视频播放页', noHasHeader: true }
      },
      {
        path: '/play/shortVideo',
        name: 'PlayShortVideo',
        component: () => import('@/views/play/shortVideo/index'),
        meta: { title: '短视频播放页', noHasHeader: true }
      },
      {
        path: '/resource',
        name: 'Resource',
        component: () => import('components/Resource/index.vue'),
        meta: { title: '引用资源', noHasHeader: true }
      },
      {
        path: '/chat/:userID',
        name: 'Chat',
        component: () => import('@/views/Chat/index'),
        meta: { title: '引用资源', noHasHeader: true }
      },
      {
        path: '/Mine/Hgame/detail',
        name: 'HgameDetail',
        component: () => import('@/views/Mine/HgameDetail/index'),
        meta: { title: '禁游详情', noHasHeader: true }
      },
      {
        path: '/bookShelf',
        name: 'BookShelf',
        component: () => import('@/views/bookshelf/index'),
        meta: { title: '书架', noHasHeader: true }
      }
    ]
  }
]
