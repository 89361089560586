const state = {
  // 搜索历史列表
  saerchlist: [],

  //播单浏览记录列表
  playList: [],
  // 播单列表
  playListData: [],
  // 动漫视频列表
  cartoonList: [],
  // 剧场列表
  dreamList: [],
  // 长视频列表
  longVideoList: [],
  shortList: [],
  // 图集列表
  photoList: [],
  // 帖子列表
  dWTJlist: [],
  // 暗网交易列表
  aWJYlist: [],
  postList: [],
  // av分类默认index
  avIndex: 0,
  //新个人中心-漫画浏览历史
  comicsHisMap: {},
  // 小视频分类默认index
  shortVideoIndex: 0,
  // 动漫分类默认index
  cartoonIndex: 0,
  // 社区分类默认index
  communityIndex: 0,

  //书架
  bookshelfList: [{ id: '默认分组', name: '默认分组', list: [] }],
  novelHisList: [],
  //个人中心-漫画浏览历史
  comicsHisList: [],
  //漫画当前章节保存
  comicChapterObj: {},
  topTabLabelList: [],
  postSelect: {},
  changeFaceItem: {},
  replyTarget: null,
  refTarget: null,
  audioPlayMethodIndex: 0
}
const getters = {
  geAudioPlayMethodIndex(state) {
    return state.audioPlayMethodIndex
  },
  getChangeFaceItem(state) {
    return state.changeFaceItem
  },
  getSaerchlist(state) {
    return state.saerchlist
  },

  getPlayList(state) {
    return state.playList
  },

  getPlayListData(state) {
    return state.playListData
  },

  getPhotoList(state) {
    return state.photoList
  },
  dWTJlist(state) {
    return state.dWTJlist
  },
  aWJYlist(state) {
    return state.aWJYlist
  },

  longVideoList(state) {
    return state.longVideoList
  },
  getShortList(state) {
    return state.shortList
  },
  getPostList(state) {
    return state.postList
  },

  getCartoonList(state) {
    return state.cartoonList
  },
  avIndex(state) {
    return state.avIndex
  },
  shortVideoIndex(state) {
    return state.shortVideoIndex
  },
  cartoonIndex(state) {
    return state.cartoonIndex
  },
  communityIndex(state) {
    return state.communityIndex
  },
  topTabLabelList(state) {
    return state.topTabLabelList
  },
  getNovelHisList(state) {
    return state.novelHisList
  },
  getComicsHisList(state) {
    return state.comicsHisList
  },
  getDreamList(state) {
    return state.dreamList
  }
}

const mutations = {
  setAudioPlayMethodIndex(state) {
    if (state.audioPlayMethodIndex < 2) {
      state.audioPlayMethodIndex++
    } else {
      state.audioPlayMethodIndex = 0
    }
  },
  setChangeFaceItem(state, item) {
    state.changeFaceItem = item
  },
  //设置书架默认分组最多100个
  setBookshelf(state, list) {
    state.bookshelfList = list
  },
  setPostSelect(state, postSelect) {
    state.postSelect = postSelect
  },
  setComicsHisMap(state, obj) {
    const { parentId, id, pageValue } = obj
    if (!state.comicsHisMap[parentId]) {
      state.comicsHisMap[parentId] = {}
    }
    state.comicsHisMap[parentId][id] = pageValue
  },
  //漫画收藏
  SET_COMICLIST(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.comicsHisList && state.comicsHisList.length > 0) {
        state.comicsHisList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.comicsHisList.splice(sIndex, 1)
          }
        })
        state.comicsHisList.unshift(item.item)
      } else {
        state.comicsHisList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.comicsHisList = item.arr
    } else {
      state.comicsHisList = [...item.item]
    }
  },
  //漫画当前章节保存
  setComicChapterObj(state, obj) {
    state.comicChapterObj = obj
  },
  SET_SEARCHLIST(state, item) {
    if (item.type === 'add') {
      if (state.saerchlist.length > 0) {
        state.saerchlist.map((sItme, sIndex) => {
          if (sItme === item.value) {
            state.saerchlist.splice(sIndex, 1)
          }
        })
        state.saerchlist.push(item.value)
      } else {
        state.saerchlist.push(item.value)
      }
    } else if (item.type === 'del') {
      state.saerchlist.splice(item.value, 1)
    } else {
      state.saerchlist = item.value
    }
  },
  SET_NOVELLIST(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.novelHisList && state.novelHisList.length > 0) {
        state.novelHisList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.novelHisList.splice(sIndex, 1)
          }
        })
        state.novelHisList.unshift(item.item)
      } else {
        state.novelHisList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.novelHisList = item.arr
    } else {
      state.novelHisList = [...item.item]
    }
  },
  SET_POSTLIST(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.postList && state.postList.length > 0) {
        state.postList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.postList.splice(sIndex, 1)
          }
        })
        state.postList.unshift(item.item)
      } else {
        state.postList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.postList = item.arr
    } else {
      state.postList = [...item.item]
    }
  },

  // 播单浏览记录
  SET_playList(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.playList && state.playList.length > 0) {
        state.playList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.playList.splice(sIndex, 1)
          }
        })
        state.playList.unshift(item.item)
      } else {
        state.playList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.playList = item.arr
    } else {
      state.playList = [...item.item]
    }
  },

  // 播单
  SET_playListData(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.playListData && state.playListData.length > 0) {
        state.playListData.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.playListData.splice(sIndex, 1)
          }
        })
        state.playListData.unshift(item.item)
      } else {
        state.playListData.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.playListData = item.arr
    } else if (item.type === 'delItem') {
      state.playListData.forEach((v, i) => {
        if (v.id == item.item.id) {
          state.playListData.splice(i, 1)
        }
      })
    } else if (item.type === 'update') {
      const index = state.playListData.findIndex(v => v.id === item.item.id)
      if (index > -1) {
        let data = { ...state.playListData[index] }
        data.cover = item.item.cover || data.cover
        data.title = item.item.title || data.title
        data.mediaIds = item.item.mediaIds || data.mediaIds || []
        data.actressphoto = item.item.actressphoto || data.actressphoto || []
        data.total = data.type === 1 ? data.mediaIds.length : data.actressphoto.length
        state.playListData.splice(index, 1, data)
      }
    } else {
      state.playListData = [...item.item]
    }
  },

  SET_LONGVIDEOLIST(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.longVideoList && state.longVideoList.length > 0) {
        state.longVideoList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.longVideoList.splice(sIndex, 1)
          }
        })
        state.longVideoList.unshift(item.item)
      } else {
        state.longVideoList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.longVideoList = item.arr
    } else {
      state.longVideoList = [...item.item]
    }
  },
  SET_shortList(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.shortList && state.shortList.length > 0) {
        state.shortList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.shortList.splice(sIndex, 1)
          }
        })
        state.shortList.unshift(item.item)
      } else {
        state.shortList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.shortList = item.arr
    } else {
      state.shortList = [...item.item]
    }
  },
  // 动漫
  SET_CARTOON(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.cartoonList && state.cartoonList.length > 0) {
        state.cartoonList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.cartoonList.splice(sIndex, 1)
          }
        })
        state.cartoonList.push(item.item)
      } else {
        state.cartoonList.push(item.item)
      }
    } else if (item.type === 'del') {
      state.cartoonList = item.arr
    } else {
      state.cartoonList = [...item.item]
    }
  },
  // 动漫
  SET_DREAM(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.dreamList && state.dreamList.length > 0) {
        state.dreamList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.dreamList.splice(sIndex, 1)
          }
        })
        state.dreamList.push(item.item)
      } else {
        state.dreamList.push(item.item)
      }
    } else if (item.type === 'del') {
      state.dreamList = item.arr
    } else {
      state.dreamList = [...item.item]
    }
  },
  SET_AVINDEX(state, id) {
    state.avIndex = id
  },
  SET_SHORTVIDEOINDEX(state, id) {
    state.shortVideoIndex = id
  },
  SET_CARTOONINDEX(state, id) {
    state.cartoonIndex = id
  },
  SET_COMMUNITYINDEX(state, id) {
    state.communityIndex = id
  },
  SET_TOPTABLABElLIST(state, item) {
    state.topTabLabelList = [...item]
  },
  SET_photoList(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.photoList && state.photoList.length > 0) {
        state.photoList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.photoList.splice(sIndex, 1)
          }
        })
        state.photoList.unshift(item.item)
      } else {
        state.photoList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.photoList = item.arr
    } else {
      state.photoList = [...item.item]
    }
  },
  SET_DWTJlist(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.dWTJlist && state.dWTJlist.length > 0) {
        state.dWTJlist.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.dWTJlist.splice(sIndex, 1)
          }
        })
        state.dWTJlist.unshift(item.item)
      } else {
        state.dWTJlist.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.dWTJlist = item.arr
    } else {
      state.dWTJlist = [...item.item]
    }
  },
  SET_AWJYlist(state, item) {
    if (item.type === 'add') {
      if (item.item.check) {
        item.item.check = false
      }
      if (state.aWJYlist && state.aWJYlist.length > 0) {
        state.aWJYlist.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.aWJYlist.splice(sIndex, 1)
          }
        })
        state.aWJYlist.unshift(item.item)
      } else {
        state.aWJYlist.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.aWJYlist = item.arr
    } else {
      state.aWJYlist = [...item.item]
    }
  },
  SET_REPLY_TARGET(state, item) {
    state.replyTarget = item
  },
  SET_REF_TARGET(state, item) {
    state.refTarget = item
  }
}
const actions = {
  setPostList({ commit }, item) {
    commit('SET_POSTLIST', item)
  },

  setPlayList({ commit }, item) {
    commit('SET_playList', item)
  },
  setPlayListData({ commit }, item) {
    commit('SET_playListData', item)
  },

  setLongVideoList({ commit }, item) {
    commit('SET_LONGVIDEOLIST', item)
  },
  setNovelHisList({ commit }, item) {
    commit('SET_NOVELLIST', item)
  },
  setComicsHisList({ commit }, item) {
    commit('SET_COMICLIST', item)
  },
  setSaerchlist({ commit }, item) {
    commit('SET_SEARCHLIST', item)
  },
  setCartoonList({ commit }, item) {
    commit('SET_CARTOON', item)
  },
  setDreamList({ commit }, item) {
    commit('SET_DREAM', item)
  },
  setAvIndex({ commit }, item) {
    commit('SET_AVINDEX', item)
  },
  setShortList({ commit }, item) {
    commit('SET_shortList', item)
  },
  setPhotoList({ commit }, item) {
    commit('SET_photoList', item)
  },
  setDWTJlist({ commit }, item) {
    commit('SET_DWTJlist', item)
  },
  setAWJYlist({ commit }, item) {
    commit('SET_AWJYlist', item)
  }
}
export default {
  state,
  mutations,
  actions,
  getters
}
