import CryptoJS from 'crypto-js'

import axios from 'axios'
import { IMAGE_DB_CONFIG, IMAGE_DB_KEY, SAVE_COUNT_MAX_KEY, START_IMAGE_DB_KEY } from '../EConfig/imgConfig'
import { decodeHttpResponseData } from './decodeResponceData'
import { decryptImage } from './imgCode'

const {
    store_name,
    table_name,
    db_option
} = IMAGE_DB_CONFIG

/**
 * 解密图片
 * @param {string} [KEY] - 图片解密密钥【主要是使用密钥的长度】
 */

const KEY = Buffer.from('2019ysapp7527')

// 图片解密加自动保存
export function handleVerAutoImg (linkImg) {
    // return Promise.resolve( getImgBuffer(linkImg))
    return new Promise(async (resolve, reject) => {
        if (!linkImg || !linkImg.length || !linkImg.includes('.')) resolve(false)
        const linkArray = linkImg.split('.')
        try {
            // 判断是否使用本地储存 还是使用网络请求

            if (!IMAGE_DB_CONFIG[START_IMAGE_DB_KEY]) {
                const originBase64 = await getImgBuffer(linkImg)
                resolve(originBase64)
            } else {
                const ImageDB = IMAGE_DB_CONFIG[IMAGE_DB_KEY]
                if (!window.indexedDB || !ImageDB || ImageDB === undefined) {
                    const originBase64 = await getImgBuffer(linkImg)
                    resolve(originBase64)
                }
                // 获取图片存储对象 并检测本地是否存储
                const transaction = ImageDB.transaction(store_name, db_option)
                const objectStore = transaction.objectStore(store_name)
                const index = objectStore.index(table_name)
                const response = index.get(linkImg)
                // 本地图片检测失败
                response.onerror = async function (event) {
                    const originBase64 = await getImgBuffer(linkImg)
                    resolve(originBase64)
                }
                response.onsuccess = async function (event) {
                    // 检测到本地存储有图片直接返回图片base64
                    if (response.result !== undefined && response.result) {
                        const { imgData } = response.result
                        resolve(imgData)
                    } else { // 本地没有缓存图片 下载后 压缩图片并直接存储 base64
                        const base64Image = await getImgBuffer(linkImg)
                        const transaction = ImageDB.transaction(store_name, db_option)
                        const objectStore = transaction.objectStore(store_name)
                        const saveRes = objectStore.put({ src: linkImg, imgData: base64Image })
                        saveRes.onsuccess = function () {
                            // 判断存储是否已经达到上限 到达上限循环写入
                            const saveIsMax = IMAGE_DB_CONFIG[SAVE_COUNT_MAX_KEY]
                            if (!saveIsMax) return
                            // 获取数据库第一个存储对象删除
                            const keyRangeValue = IDBKeyRange.lowerBound(1)
                            objectStore.openCursor(keyRangeValue).onsuccess = function (event) {
                                const cursor = event.target.result
                                if (cursor) cursor.delete()
                            }
                        }
                        resolve(base64Image)
                    }
                }
            }
        } catch (e) {
            reject(e)
        }
    })
}

function getImgBuffer (linkImg) {
    return new Promise((resolve, reject) => {
        axios.get(linkImg, { responseType: 'arraybuffer', timeout: 100000 }).then((res) => {
            const data = res.data
            const bytes = new Uint8Array(data)
            decryptImage(bytes)
            let reader = new FileReader();
            reader.readAsDataURL(new Blob([data])); // 将 Blob 转换为 base64 并调用 onload

            reader.onload = function() {
                resolve( reader.result)
            };
        }).catch((e) => reject(e))
    })
}

/**
 * 单图解密图片主方法【被动调用方法】
 * @param {string} [linkImg] - 图片地址
 */
export function handleVerImg (linkImg) {
    return new Promise((resolve) => {
        axios
            .get(linkImg, { responseType: 'arraybuffer', timeout: 100000 })
            .then((res) => {
                const data = res.data
                const bytes = new Uint8Array(data)
                for (let i = 0; i < 100; i++) {
                    bytes[i] ^= KEY[i % KEY.length]
                }
                // const type = 'data:image/png;base64'
                // const imgBase64 = type + transformArrayBufferToBase64(bytes);
                resolve(bytes)
                // resolve((window.URL) ? window.URL.createObjectURL(new Blob([bytes])) : window.webkitURL.createObjectURL(new Blob([bytes])))
            }).catch(err => {
                console.log(err);
            })
    })
}
// 请求参数加密
export function decodeParamToHttpRequest (params) {
    const { token, apiPath, XUserAgent, serect } = params
    const timestamp = parseInt(new Date(new Date().toUTCString()).getTime() / 1000)
    const nonce = getUuid()
    const signSting = token + '&' + apiPath + '&' + XUserAgent + '&' + timestamp.toString() + '&' + nonce
    // console.log('计算内容',signSting, 'token====+',token, 'apiPath====+',apiPath, 'XUserAgent====+',XUserAgent, 'serect====+',serect)

    const Sha1Encrypt = CryptoJS.HmacSHA1(signSting, serect)
    // console.log('计算结果', Sha1Encrypt)
    return `timestamp=${timestamp};sign=${Sha1Encrypt};nonce=${nonce}`
    function getUuid () {
        const s = []
        const hexDigits = '0123456789abcdef'
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
        }
        s[14] = '4'
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
        s[8] = s[13] = s[18] = s[23] = '-'

        return s.join('')
    }
}
// 返回数据解密
export function decodeDataToHttpResponse (params) {
    if (params.code === 200 && params.hash) {
        const data = params.data
        params.data = JSON.parse(decodeHttpResponseData(data))
        return params
    } else {
        return params
    }
}

// 中间件下载图片转blob
export function dataURItoBlob (base64Data) {
    var byteString
    if (base64Data.split(',')[0].indexOf('base64') >= 0) {
        byteString = window.atob(base64Data.split(',')[1])
    } else {
        // base64 解码
        byteString = unescape(base64Data.split(',')[1])
    }
    var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0] // mime类型 -- image/png

    // var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
    // var ia = new Uint8Array(arrayBuffer);//创建视图
    var ia = new Uint8Array(byteString.length) // 创建视图
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }
    var blob = new Blob([ia], {
        type: mimeString
    })
    return blob
}

//  根据所需金币判断当前用户是否有足够金币购买  price 所需金币
export function canBuy (store, price) {
    if (store.state.user.info.balance / 100 < price / 100) {
        return false
    } else {
        return true
    }
}

// 单图、多图压缩
export function imgsCompress (fileList) {
    const promises = fileList.map((element) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(element.file)
            reader.onload = e => {
                const image = new Image()
                image.src = e.target.result
                image.onload = function () {
                    // 默认压缩后图片规格
                    const quality = 0.5
                    const w = this.width
                    const h = this.height
                    const canvas = document.createElement('canvas')
                    const context = canvas.getContext('2d')
                    canvas.width = w
                    canvas.height = h
                    context.drawImage(image, 0, 0, w, h)
                    canvas.toBlob(
                        blob => {
                            const strName = element.file.name.split('.')[0] + '.jpeg'
                            const obj = new File([blob], strName, { type: 'image/jpeg', lastModified: new Date() })
                            resolve(obj)
                        },
                        'image/jpeg',
                        quality
                    )
                }
            }
        })
    })
    return Promise.all(promises)
}

// fn是我们需要包装的事件回调, delay是时间间隔的阈值
export function throttle (fn, delay) {
    // last为上一次触发回调的时间, timer是定时器
    let last = 0,
        timer = null;
    // 将throttle处理结果当作函数返回
    return function () {
        // 保留调用时的this上下文
        let context = this;
        // 保留调用时传入的参数
        let args = arguments;
        // 记录本次触发回调的时间
        let now = +new Date();
        // 判断上次触发的时间和本次触发的时间差是否小于时间间隔的阈值
        if (now - last < delay) {
            // 如果时间间隔小于我们设定的时间间隔阈值，则为本次触发操作设立一个新的定时器
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, delay);
        } else {
            // 如果时间间隔超出了我们设定的时间间隔阈值，那就不等了，无论如何要反馈给用户一次响应
            last = now;
            fn.apply(context, args);
        }
    };
}

export function isIphonex () { // 判断是不是X类型手机
    // X XS, XS Max, XR，11， 11pro，11pro max，12mini，12， 12 pro，12 pro max
    const xSeriesConfig = [
        {
            devicePixelRatio: 3,
            width: 375,
            height: 812,
        },
        {
            devicePixelRatio: 3,
            width: 414,
            height: 896,
        },
        {
            devicePixelRatio: 2,
            width: 414,
            height: 896,
        },
        {
            devicePixelRatio: 3,
            width: 315,
            height: 812,
        },
        {
            devicePixelRatio: 3,
            width: 390,
            height: 844,
        },
        {
            devicePixelRatio: 3,
            width: 428,
            height: 926,
        }
    ];
    // h5
    if (typeof window !== 'undefined' && window) {
        const isIOS = /iphone/gi.test(window.navigator.userAgent);
        if (!isIOS) return false;
        const { devicePixelRatio, screen } = window;
        const { width, height } = screen;
        return xSeriesConfig.some(item => item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height);
    }
    return false;
}


export function fallback (vim) {
    const landRouteName = window.sessionStorage.getItem('landRouteName')
    console.log(landRouteName)

    if (vim.$route.name === landRouteName) {
        vim.$router.push("/");
        // 重置为首页
        window.sessionStorage.setItem('landRouteName', 'index')
    } else {
        vim.$router.go(-1);
    }
}



export  const isWhiteList = (isPLay) => {
    // hasPreVideoUrl 是否有预告片
    // isPLay  是否能播放正片
    const ua = navigator.userAgent.toLowerCase();
    const isMobile =  navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    const whiltList = [
      // {
      //   name: 'baidu',
      //   test: () => { return ua.includes('baidu') }
      // },
     {
        name: 'edge',
        test: () => { return ua.includes('edg') }
      },
      {
        name: 'firefox',
        test: () => { return (ua.includes('firefox') || ua.includes('fxios') ) }
      },
    //   {
    //     name: 'sogou',
    //     test: () => { return ua.includes('sogou') }
    //   },
      {
        name: '2345',
        test: () => { return ua.includes('2345') }
      },
      {
        name: 'opr',
        test: () => { return ua.includes('opr') }
      },        
      {
        name: 'safari',
        test: () => {
          if (
            ua.indexOf('applewebkit') > -1 &&
            ua.indexOf('mobile') > -1 &&
            ua.indexOf('safari') > -1 &&
            ua.indexOf('linux') === -1 &&
            ua.indexOf('20f') === -1 &&
            ua.indexOf('android') === -1 &&
            ua.indexOf('chrome') === -1 &&
            ua.indexOf('ios') === -1 &&
            ua.indexOf('browser') === -1
          ) {
            return true;
          } 
        }
      },

      {
        name:'chrome',
        test:()=>{
          var isChromium = window.chrome;
          var winNav = window.navigator;
          var vendorName = winNav.vendor;
          var isOpera = typeof window.opr !== "undefined";
          var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
          var isIOSChrome = winNav.userAgent.match("CriOS");

          if (isIOSChrome) {
            return true
          } else if(
            isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." &&
            isOpera === false &&
            ua.indexOf('20f') === -1 &&
            isIEedge === false
          ) {
            return true
          } else { 
             return false
          }
        }
      }

    ]
    let isFind = whiltList.find(item => (item.test()))
      if(!!isFind && isMobile){   
        return true
      }else{
          // if(hasPreVideoUrl){   

            // 播放预览视频付费弹窗无法弹出
          //     return true
              
          // }else{
              if(isPLay){
                 return true
              }
          // }
      }
  }


export default async function ({ app: { router }, store, route, redirect, query }) {
    if (process.client) {
        if (!window.sessionStorage.getItem("landRouteName")) {
            window.sessionStorage.setItem("landRouteName", route.name)
        }
    }
}

