import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { Toast, Dialog } from 'vant'
import { decodeDataToHttpResponse, decodeParamToHttpRequest } from '@/utils/utils_tools'
// 根据环境不同引入不同api地址
import { baseApi } from '@/config'
const UAParser = require('ua-parser-js')
const $device = require('current-device').default

// create an axios instance
const service = axios.create({
  baseURL: baseApi, // url = base api url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000, // request timeout
  headers: {
    'Content-Type': 'application/json'
  }
})
// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    // 不传递默认开启loading
    // if (!config.hideloading) {
    //   // loading
    //   Toast.loading({
    //     forbidClick: true
    //   })
    // }
    // // 设置x-user-agent
    const userAgent = config.headers['user-agent']
    const obj = new UAParser(userAgent)
    const results = obj.getResult()

    let DevType = results.device.vendor + ' ' + results.device.model + ' ' + results.device.type
    if (!results.device.vendor || !results.device.model || !results.device.type) {
      DevType = 'Apple iPhone mobile'
    }
    let SysType = ''
    const BuildID = results.browser.name + ' ' + results.browser.version
    config.headers.serect = 'kaFttDJRcahRMTI7' // kaFttDJRcahRMTI7  fkf34lKD9344s6F8
    const Ver = store.state.config.ver

    if (store.state.user.isLight) {
      SysType = 'light'
    } else {
      if ($device.desktop()) {
        SysType = 'h5_pc'
      }
      if ($device.android()) {
        SysType = 'h5_android'
      }
      if ($device.ios()) {
        SysType = 'h5_ios'
      }
    }
    config.headers['X-User-Agent'] =
      'DevType=' + DevType + ';' + 'SysType=' + SysType + ';' + 'Ver=' + Ver + ';' + 'BuildID=' + BuildID
    // 配置加密信息
    const XUserAgent = config.headers['X-User-Agent']
    const token = store.getters['getToken']
    const apiPath = config.url
    const serect = config.headers.serect
    config.headers['x-api-key'] = decodeParamToHttpRequest({ token, apiPath, XUserAgent, serect })
    config.headers.Authorization = token
    if (process.env.NODE_ENV !== 'production') {
      console.log('Making request to ' + config.url)
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  async response => {
    Toast.clear()
    const res = response.data
    if (res.code && res.code === 200) {
      if (
        response.headers &&
        response.headers['refresh-authorization'] &&
        response.headers['refresh-authorization'] !== ''
      ) {
        store.dispatch('setToken', response.headers['refresh-authorization'])
      }
    }
    // 调用游客生成方法
    if (res.code === 1015) {
      await store.dispatch('clearInfo')
      Dialog.confirm({
        title: '友情提示',
        message: '账号不能同时在两个客户端登录！',
        confirmButtonText: '重新登陆',
        cancelButtonText: '游客进入'
      })
        .then(async () => {
          await store.dispatch('clearUid')
          router.push('/Mine/login')
        })
        .catch(() => {
          //  替换错误请求的token
          response.headers['refresh-authorization'] = store.getters['getToken']
          return location.reload()
        })
    } else if (res.code === 1000) {
      await store.dispatch('clearInfo')
      Dialog.confirm({
        title: '友情提示',
        message: '用户信息获取失败',
        confirmButtonText: '重新登陆',
        cancelButtonText: '游客进入'
      })
        .then(async () => {
          await store.dispatch('clearUid')
          router.push('/Mine/login')
        })
        .catch(async () => {
          //  替换错误请求的token
          await store.dispatch('clearUid')
          store.commit('SET_FIRSTLOGIN', true)
          return location.reload()
        })
    }
    return decodeDataToHttpResponse(res)
  },
  async error => {
    Toast.clear()
    const res = error.response.data
    // 调用游客生成方法
    if (res.code === 1015) {
      await store.dispatch('clearInfo')
      Dialog.confirm({
        title: '友情提示',
        message: '账号不能同时在两个客户端登录！',
        confirmButtonText: '重新登陆',
        cancelButtonText: '游客进入'
      })
        .then(async () => {
          await store.dispatch('clearUid')
          router.push('/mine/login')
        })
        .catch(() => {
          //  替换错误请求的token
          error.response.headers['refresh-authorization'] = store.getters['getToken']
        })
    } else if (res.code === 1000) {
      await store.dispatch('clearInfo')
      Dialog.confirm({
        title: '友情提示',
        message: '用户信息获取失败',
        confirmButtonText: '重新登陆',
        cancelButtonText: '游客进入'
      })
        .then(async () => {
          await store.dispatch('clearUid')
          router.push('/Mine/login')
        })
        .catch(async () => {
          //  替换错误请求的token
          await store.dispatch('clearUid')
          store.commit('SET_FIRSTLOGIN', true)
          return location.reload()
        })
    } else {
      console.log('err' + error) // for debug
      return Promise.reject(error)
    }
  }
)

export default service
