import Vue from 'vue'
import Vuex from 'vuex'
import config from './modules/config'
import history from './modules/history'
import shortVideo from './modules/shortVideo'
import user from './modules/user'
import gestureLock from './modules/gestureLock'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
// 存到local的数据
// const dataLocal =
// 存到session的数据
// const dataSession =

const store = new Vuex.Store({
  modules: {
    config,
    history,
    shortVideo,
    user,
    gestureLock
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: 'vueX',
      reducer(val) {
        return {
          // 只存储state中的userState
          history: val.history,
          user: val.user,
          config: val.config,
          gestureLock: val.gestureLock
        }
      }
    })
  ]
})
export default store
