import qs from 'qs'
// axios
import request from '@/utils/request'
// home api
import api from './index'

const BASEURL = '/api/app'

// 系统配置
export function config(data) {
  return request({
    url: api.config,
    method: 'get',
    data
  })
}

export function faq_list(data) {
  return request({
    url: api.faq_list,
    method: 'get',
    params: data,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'indices' })
    }
  })
}

export function index_home(data) {
  return request({
    url: api.index_home,
    method: 'post',
    data
  })
}
// 收藏统一接口 collect
/**
 *
 *ContentTypeNone         ContentType = 0 // 占位符，无意义
  ContentTypeVideoAv      ContentType = 1 // AV
  ContentTypeVideoMedia   ContentType = 2 // 小视频
  ContentTypeCartoon      ContentType = 3 // 动漫
  ContentTypeViodeModel   ContentType = 4 // 上门
  ContentTypePost         ContentType = 5 // 帖子
  ContentTypeViodeLoufeng ContentType = 6 // 楼风
  ContentTypeActor        ContentType = 7 // 女优
  0  type
1 //收藏
2 //点赞
3 //踩
 */
export function collect(data) {
  return request({
    url: api.collect,
    method: 'post',
    data
  })
}
// 关注目标
export function care_add(data) {
  return request({
    url: api.care_add,
    method: 'post',
    data
  })
}
// 取消关注
export function care_cancel(data) {
  return request({
    url: api.care_cancel,
    method: 'post',
    data
  })
}
// 获取交易方式及列表
export function paytypeinfo(data) {
  return request({
    url: api.paytypeinfo,
    method: 'post',
    data
  })
}

// 多图上传
export function uploadImgs(data) {
  return request({
    url: api.uploadImgs,
    method: 'post',
    data
  })
}
// 单图上传
export function uploadOneImg(data) {
  return request({
    url: api.uploadOneImg,
    method: 'post',
    data
  })
}
// 视频上传
export function uploadVideo(data) {
  return request({
    url: api.uploadVideo,
    method: 'post',
    data
  })
}
// 获取评论统一接口 /api/app/comment/list
/**
 *
 * @param objectType
 * CommentTypeVideo CommentType = 1 // 视频
  CommentTypeInfo  CommentType = 2 // 帖子
 */
export function comment_list(data) {
  return request({
    url: api.comment_list,
    method: 'post',
    data
  })
}
// 发布评论
export function comment_add(data) {
  return request({
    url: api.comment_add,
    method: 'post',
    data
  })
}
// 获取会员卡列表
export function vip_list(data) {
  return request({
    url: api.vip_list,
    method: 'post',
    data
  })
}
// 充值
export function recharge_sumbit(data) {
  return request({
    url: api.recharge_sumbit,
    method: 'post',
    data
  })
}
// 获取验证码
export function login_captcha(data) {
  return request({
    url: api.login_captcha,
    method: 'post',
    data
  })
}
// 绑定手机号
export function bind_Phone(data) {
  return request({
    url: api.bind_Phone,
    method: 'post',
    data
  })
}
// 手机号登录
export function phone_login(data) {
  return request({
    url: api.phone_login,
    method: 'post',
    data
  })
}
// 客服
export function kf(data) {
  return request({
    url: api.kf,
    method: 'post',
    data
  })
}
// 应用列表
export function apps_list(data) {
  return request({
    url: api.apps_list,
    method: 'post',
    data
  })
}
// 用户签到
export function checkin_click(data) {
  return request({
    url: api.checkin_click,
    method: 'post',
    data
  })
}
// 签到记录
export function checkin_list(data) {
  return request({
    url: api.checkin_list,
    method: 'post',
    data
  })
}
// 签到规则
export function checkin_setup(data) {
  return request({
    url: api.checkin_setup,
    method: 'post',
    data
  })
}
// 视频详情列表数据
export function media_details(data) {
  return request({
    url: api.media_details,
    method: 'post',
    data
  })
}
// 女优详情数据
export function media_actor_info(data) {
  return request({
    url: api.media_actor_info,
    method: 'post',
    data
  })
}
// 女优视频详情数据
/*contentType	integer
内容类型： 0:视频 1:图集 2:GIFs

coverType	integer
封面类型： 1:横版封面 2:竖版封面

id	integer
演员ID

pageNum*	integer
分页参数

pageSize*	integer
分页参数

sort	integer
排序: 0:最新 1:最热 2:最受好评

tagIds	[
视频标签

integer]*/
export function media_actor_details(data) {
  return request({
    url: api.media_actor_details,
    method: 'post',
    data
  })
}
// 合集购买
export function gather_buy(data) {
  return request({
    url: api.gather_buy,
    method: 'post',
    data
  })
}

//首页短视频推荐列表/media/short/featured
export function homeRecommendList(data) {
  return request({
    url: BASEURL + '/media/short/recommend',
    method: 'post',
    data
  })
}

//首页短视频关注列表
export function homeFocusList(data) {
  return request({
    url: BASEURL + '/media/short/focus',
    method: 'post',
    data
  })
}

//首页短视频热门列表
export function homeFeaturedList(data) {
  return request({
    url: BASEURL + '/media/short/featured',
    method: 'post',
    data
  })
}

// 首页头部tab标签获取
export function homeTopicList(data) {
  return request({
    url: BASEURL + '/media/typeTopic/topicList',
    method: 'post',
    data
  })
}

// 视频分类列表
export function typeList(data) {
  return request({
    url: BASEURL + '/media/typeTopic/typeList',
    method: 'post',
    data
  })
}
//获取视频分类列表
export function getRankType(data) {
  return request({
    url: BASEURL + '/rankType/list',
    method: 'post',
    data
  })
}
export function getRankTypeList(data) {
  return request({
    url: BASEURL + '/rankType/mediaList',
    method: 'post',
    data
  })
}

export function downApp(data) {
  return request({
    url: '/api/stat/version/bottonclick',
    method: 'post',
    data
  })
}

export function videoTopicChange(data) {
  return request({
    url: BASEURL + '/media/topic/change',
    method: 'post',
    data
  })
}
export function appsClick(data) {
  return request({
    url: api.appsClick,
    method: 'post',
    data
  })
}
export function advertiseClick(data) {
  return request({
    url: api.advertiseClick,
    method: 'post',
    data
  })
}
// 禁游列表
export function hGameList(data) {
  return request({
    url: '/api/app/hGame/list',
    method: 'post',
    data
  })
}
// 禁游详情
export function hGameDetail(data) {
  return request({
    url: '/api/app/hGame/detail',
    method: 'post',
    data
  })
}
// 禁游购买
export function hGamePay(data) {
  return request({
    url: '/api/app/hGame/pay',
    method: 'post',
    data
  })
}
// 常见问题解答
export function aicustomerserviceList(data) {
  return request({
    url: api.aicustomerserviceList,
    method: 'get',
    data
  })
}