const _eventDispatcher = (_element, name, message) => {
  const event = new CustomEvent(name, {
    detail: {
      message,
    },
  });
  _element.dispatchEvent(event);
};

export const isAppleMobile = function () {
  if ((['iPhone', 'iPad', 'iPod'].includes(navigator.platform) || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)) && 'serviceWorker' in navigator)
    return true;
  return false;
};

export const isStandalone = function () {
  return window.matchMedia('(display-mode: standalone)').matches || ('standalone' in navigator && navigator.standalone === true);
};

export const getInstalledRelatedApps = async function () {
  if ('getInstalledRelatedApps' in navigator)
    await navigator.getInstalledRelatedApps().then(relatedApps => {
      return relatedApps;
    });
  return [];
};

export const isRelatedAppsInstalled = async function () {
  const _relatedApps = await getInstalledRelatedApps();
  return _relatedApps.length ? true : false;
};

export const eventInstalledSuccess = function (_element) {
  // _eventDispatcher(_element, 'pwa-install-success-event', 'Application installation successfully processed (Chromium/Android only)');
};

export const eventInstalledFail = function (_element) {
  // _eventDispatcher(_element, 'pwa-install-fail-event', 'Application installation failed (Chromium/Android only)');
};

export const eventUserChoiceResult = function (_element, message) {
  // _eventDispatcher(_element, 'pwa-user-choice-result-event', message);
};

export const eventInstallAvailable = function (_element) {
  // _eventDispatcher(_element, 'pwa-install-available-event', 'Application installation available');
};
