/*
 * @Author: 江涛 wangtao524713@gmail.com
 * @Date: 2024-01-31 14:38:12
 * @LastEditors: 江涛 wangtao524713@gmail.com
 * @LastEditTime: 2024-02-10 14:32:46
 * @FilePath: /haoseh5/src/assets/lib/rem.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

(function (doc, win) {
  var docEl = doc.documentElement;
  function IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }
  docEl.style.display = 'none';
  //h5
  resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      if (IsPC()) return docEl.style.fontSize = '75px', docEl.style.display = 'block';
      if (clientWidth >= 750) {
        docEl.style.fontSize = '100px';
      } else {
        docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
      }
      docEl.style.display = 'block';
    };

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);
