/**
 * 导入
 * @requires [axios] - axios方法
 */
import CryptoJS from 'crypto-js'

import { comicsChapterIsLook, comicsChapterPics, comicsPay, novelChapterIsLook, novelPay } from '@/api/acg'
import {
  IMAGE_DB_CONFIG,
  IMAGE_DB_KEY,
  SAVE_COUNT_MAX_KEY,
  START_IMAGE_DB_KEY
} from '@/components/web_base/utils_base/EConfig/imgConfig'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import { Toast } from 'vant'
import { decodeHttpResponseData } from './decodeResponceData'
import { decryptImage } from './imgCode'

//漫画api
// import { comicsPay,comicsChapterIsLook,comicsChapterPics} from 'api/comics' //api列表

const { store_name, table_name, db_option } = IMAGE_DB_CONFIG

/**
 * 解密图片
 * @param {string} [KEY] - 图片解密密钥【主要是使用密钥的长度】
 */

const KEY = Buffer.from('2019ysapp7527')

// 图片解密加自动保存
export function handleVerAutoImg(linkImg) {
  return new Promise(async (resolve, reject) => {
    if (!linkImg || !linkImg.length || !linkImg.includes('.')) resolve(false)
    const linkArray = linkImg.split('.')
    try {
      // 判断是否使用本地储存 还是使用网络请求

      if (!IMAGE_DB_CONFIG[START_IMAGE_DB_KEY]) {
        const originBase64 = await getImgBuffer()
        resolve(originBase64)
      } else {
        const ImageDB = IMAGE_DB_CONFIG[IMAGE_DB_KEY]
        if (!window.indexedDB || !ImageDB || ImageDB === undefined) {
          const originBase64 = await getImgBuffer()
          resolve(originBase64)
        }
        // 获取图片存储对象 并检测本地是否存储
        const transaction = ImageDB.transaction(store_name, db_option)
        const objectStore = transaction.objectStore(store_name)
        const index = objectStore.index(table_name)
        const response = index.get(linkImg)
        // 本地图片检测失败
        response.onerror = async function (event) {
          const originBase64 = await getImgBuffer()
          resolve(originBase64)
        }
        response.onsuccess = async function (event) {
          // 检测到本地存储有图片直接返回图片base64
          if (response.result !== undefined && response.result) {
            const { imgData } = response.result
            resolve(imgData)
          } else {
            // 本地没有缓存图片 下载后 压缩图片并直接存储 base64
            const base64Image = await getImgBuffer()
            const transaction = ImageDB.transaction(store_name, db_option)
            const objectStore = transaction.objectStore(store_name)
            const saveRes = objectStore.put({ src: linkImg, imgData: base64Image })
            saveRes.onsuccess = function () {
              // 判断存储是否已经达到上限 到达上限循环写入
              const saveIsMax = IMAGE_DB_CONFIG[SAVE_COUNT_MAX_KEY]
              if (!saveIsMax) return
              // 获取数据库第一个存储对象删除
              const keyRangeValue = IDBKeyRange.lowerBound(1)
              objectStore.openCursor(keyRangeValue).onsuccess = function (event) {
                const cursor = event.target.result
                if (cursor) cursor.delete()
              }
            }
            resolve(base64Image)
          }
        }
      }
    } catch (e) {
      reject(e)
    }
    function getBase64(result) {
      const bufArr = window.btoa(result)
      const baseImage = bufArr.substring(bufArr.indexOf(',') + 1)
      const realBase64 = 'data:image/'.concat(linkArray[linkArray.length - 1], ';base64,').concat(baseImage)
      return realBase64
    }

    function getImgBuffer() {
      return new Promise((resolve, reject) => {
        axios
          .get(linkImg, { responseType: 'arraybuffer', timeout: 100000 })
          .then(res => {
            const data = res.data
            let binary = ''
            const bytes = new Uint8Array(data)
            const decryptBytes = decryptImage(bytes)

            const len = decryptBytes.byteLength
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(decryptBytes[i])
            }
            resolve(getBase64(binary))
          })
          .catch(e => reject(e))
      })
    }
  })
}

/**
 * 单图解密图片主方法【被动调用方法】
 * @param {string} [linkImg] - 图片地址
 */
export function handleVerImg(linkImg) {
  return new Promise(resolve => {
    axios
      .get(linkImg, { responseType: 'arraybuffer', timeout: 100000 })
      .then(res => {
        const data = res.data
        const bytes = new Uint8Array(data)
        for (let i = 0; i < 100; i++) {
          bytes[i] ^= KEY[i % KEY.length]
        }
        // const type = 'data:image/png;base64'
        // const imgBase64 = type + transformArrayBufferToBase64(bytes);
        resolve(bytes)
        // resolve((window.URL) ? window.URL.createObjectURL(new Blob([bytes])) : window.webkitURL.createObjectURL(new Blob([bytes])))
      })
      .catch(err => {
        console.log(err)
      })
  })
}
// 请求参数加密
export function decodeParamToHttpRequest(params) {
  const { token, apiPath, XUserAgent, serect } = params
  const timestamp = parseInt(new Date(new Date().toUTCString()).getTime() / 1000)
  const nonce = getUuid()
  const signSting = token + '&' + apiPath + '&' + XUserAgent + '&' + timestamp.toString() + '&' + nonce
  // console.log('计算内容',signSting, 'token====+',token, 'apiPath====+',apiPath, 'XUserAgent====+',XUserAgent, 'serect====+',serect)

  const Sha1Encrypt = CryptoJS.HmacSHA1(signSting, serect)
  // console.log('计算结果', Sha1Encrypt)
  return `timestamp=${timestamp};sign=${Sha1Encrypt};nonce=${nonce}`
  function getUuid() {
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = '-'

    return s.join('')
  }
}
// 返回数据解密
export function decodeDataToHttpResponse(params) {
  if (params.code === 200 && params.hash) {
    const data = params.data
    params.data = JSON.parse(decodeHttpResponseData(data))
    return params
  } else {
    return params
  }
}

export function isUcOrBaiduOrShougouBrowser(params) {
  if (process.client) {
    const ua = navigator.userAgent.toLowerCase() || window.navigator.userAgent.toLowerCase() || ''
    const isUC = /UCBrowser|ucbrowser|UCWEB/.test(ua)
    const isBAIDU = /baidu|baiduboxapp|BAIDUBrowser|baidubrowser|baidumobilebrowser/.test(ua)
    const isSG = /sogoumobilebrowser|SE|MetaSr/.test(ua)
    const isWEIBO = /Weibo|weibo/.test(ua)
    return {
      isUC,
      isBAIDU,
      isSG,
      isWEIBO
    }
  }
}
//漫画章节权限跳转  this  type 1 漫画 2小说 ｜ picsId 章节id
export async function picViewRightTo(evtThis, picsId, type, price, isCollect, shoudBuy, novelId) {
  let routeObj = { id: '', nowPage: '' }
  //漫画预览页
  if (evtThis.$route.path.includes('/acg/ComicsDetail')) {
    routeObj.id = +evtThis.$route.query.id
    routeObj.nowPage = 'ComicsDetail'
  }
  if (evtThis.$route.path.includes('/acg/ComicsReadPage')) {
    routeObj.id = +evtThis.$route.query.id
    routeObj.nowPage = 'ComicsReadPage'
  }
  if (evtThis.$route.path.includes('/acgNovelDetail')) {
    routeObj.id = +evtThis.$route.query.id
    routeObj.nowPage = 'NovelDetail'
  }
  if (evtThis.$route.path.includes('/acg/player')) {
    routeObj.id = +evtThis.$route.query.id
    routeObj.nowPage = 'NovelPlayer'
  }
  let resOne = {}

  //漫画跳转
  if (type === 1) {
    resOne = await comicsChapterIsLook({
      id: picsId
    })
    // 是否可以观看
    if (resOne.data.code === 6102) {
      evtThis.$refs['popupMsg'].popupMsg = {
        show: true,
        title: '立即支付',
        price: price / 100,
        type: 'coin',
        ok: '立即支付',
        cancel: '前往充值',
        ischeck: canBuy(evtThis.$store, price) ? true : false,
        cb: async returnData => {
          const res = await comicsPay({
            id: routeObj.id
          })
          if (res.data.code === 200) {
            evtThis.$refs['popupMsg'].popupMsg.show = false
            evtThis.$store.dispatch('get_userInfo')
            evtThis.$toast('购买成功')
          } else {
            evtThis.$router.push('/Mine/myWallet')
            return evtThis.$toast(res.data.msg)
          }
        }
      }
    } else if (resOne.data.code === 6101) {
      evtThis.$refs['popupMsg'].popupMsg = {
        show: true,
        type: 'text'
      }
    } else if (!resOne.data.playable) {
      evtThis.$refs['popupMsg'].popupMsg = {
        show: true,
        type: 'text'
      }
    } else {
      //获取章节内容
      evtThis.$toast('加载章节中...')
      const res = await comicsChapterPics({
        id: picsId
      })
      if (res.data && res.code === 200) {
        res.data['isCollect'] = isCollect
        res.data['price'] = price
        res.data['shoudBuy'] = shoudBuy
        evtThis.$store.commit('setComicChapterObj', res.data)
        if (routeObj.nowPage === 'ComicsDetail') {
          evtThis.$router.push({
            path: `/acg/ComicsReadPage`,
            query: {
              id: routeObj.id,
              picsId
            }
          })
        } else if (routeObj.nowPage === 'ComicsReadPage') {
          evtThis.$router.replace({
            path: `/acg/ComicsReadPage`,
            query: {
              id: routeObj.id,
              picsId
            }
          })
        }
      } else {
        evtThis.$toast(res.data.msg || res.tip)
      }
    }
  }
  //小说跳转
  if (type === 2) {
    resOne = await novelChapterIsLook({
      id: picsId
    })
    // 是否可以观看
    if (resOne.data.code === 6139) {
      evtThis.$refs['popupMsg'].popupMsg = {
        show: true,
        title: '立即支付',
        content: price / 100,
        type: 'coin',
        ok: '',
        cancel: '',
        position: 'bottom',
        ischeck: canBuy(evtThis.$store, price) ? true : false,
        cb: async returnData => {
          const res = await novelPay({
            id: routeObj.id
          })
          if (res.data.code === 200) {
            evtThis.$refs['popupMsg'].popupMsg.show = false
            this.$store.dispatch('get_userInfo')
            evtThis.$toast('购买成功')
          } else {
            evtThis.$router.push('/Mine/myWallet')
            return evtThis.$toast(res.data.msg)
          }
        }
      }
    } else if (resOne.data.code === 6101 || resOne.data.code === 6142) {
      evtThis.$refs['popupMsg'].popupMsg = {
        show: true,
        type: 'text'
      }
    } else {
      if (evtThis.$route.path.includes('/acg/player')) {
        return 'reload'
      }
      evtThis.$router.push({
        path: '/acg/player',
        query: {
          id: novelId,
          chapterId: picsId
        }
      })
    }
  }
}

// 中间件下载图片转blob
export function dataURItoBlob(base64Data) {
  var byteString
  if (base64Data.split(',')[0].indexOf('base64') >= 0) {
    byteString = window.atob(base64Data.split(',')[1])
  } else {
    // base64 解码
    byteString = unescape(base64Data.split(',')[1])
  }
  var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0] // mime类型 -- image/png

  // var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
  // var ia = new Uint8Array(arrayBuffer);//创建视图
  var ia = new Uint8Array(byteString.length) // 创建视图
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  var blob = new Blob([ia], {
    type: mimeString
  })
  return blob
}

//  根据所需金币判断当前用户是否有足够金币购买  price 所需金币
export function canBuy(store, price) {
  if (store.state.user.info.balance / 100 < price / 100) {
    return false
  } else {
    return true
  }
}

// 单图、多图压缩
export function imgsCompress(fileList) {
  const promises = fileList.map(element => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(element.file)
      reader.onload = e => {
        const image = new Image()
        image.src = e.target.result
        image.onload = function () {
          // 默认压缩后图片规格
          const quality = 0.5
          const w = this.width
          const h = this.height
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.width = w
          canvas.height = h
          context.drawImage(image, 0, 0, w, h)
          canvas.toBlob(
            blob => {
              const strName = element.file.name.split('.')[0] + '.jpeg'
              const obj = new File([blob], strName, { type: 'image/jpeg', lastModified: new Date() })
              resolve(obj)
            },
            'image/jpeg',
            quality
          )
        }
      }
    })
  })
  return Promise.all(promises)
}

// fn是我们需要包装的事件回调, delay是时间间隔的阈值
export function throttle(fn, delay) {
  // last为上一次触发回调的时间, timer是定时器
  let last = 0,
    timer = null
  // 将throttle处理结果当作函数返回
  return function () {
    // 保留调用时的this上下文
    let context = this
    // 保留调用时传入的参数
    let args = arguments
    // 记录本次触发回调的时间
    let now = +new Date()
    // 判断上次触发的时间和本次触发的时间差是否小于时间间隔的阈值
    if (now - last < delay) {
      // 如果时间间隔小于我们设定的时间间隔阈值，则为本次触发操作设立一个新的定时器
      clearTimeout(timer)
      timer = setTimeout(function () {
        last = now
        fn.apply(context, args)
      }, delay)
    } else {
      // 如果时间间隔超出了我们设定的时间间隔阈值，那就不等了，无论如何要反馈给用户一次响应
      last = now
      fn.apply(context, args)
    }
  }
}

export function isIphonex(index) {
  // 判断是不是X类型手机
  // X XS, XS Max, XR，11， 11pro，11pro max，12mini，12， 12 pro，12 pro max
  const xSeriesConfig = [
    {
      devicePixelRatio: 3,
      width: 375,
      height: 812
    },
    {
      devicePixelRatio: 3,
      width: 414,
      height: 896
    },
    {
      devicePixelRatio: 2,
      width: 414,
      height: 896
    },
    {
      devicePixelRatio: 3,
      width: 315,
      height: 812
    },
    {
      devicePixelRatio: 3,
      width: 390,
      height: 844
    },
    {
      devicePixelRatio: 3,
      width: 428,
      height: 926
    }
  ]
  // h5
  if (typeof window !== 'undefined' && window) {
    const isIOS = /iphone/gi.test(window.navigator.userAgent)
    if (!isIOS) return false
    const { devicePixelRatio, screen } = window
    const { width, height } = screen
    if (index) {
      const item = xSeriesConfig[index]
      return item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height
    }
    return xSeriesConfig.some(
      item => item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height
    )
  }
  return false
}

export function downVideo(url, name, type) {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'arraybuffer' // 返回类型blob
  xhr.onload = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let blob = this.response
      // 转换一个blob链接
      let u = window.URL.createObjectURL(new Blob([blob], { type: type == 'video' ? 'video/mp4' : 'image/jpeg' })) // 视频的type是video/mp4，图片是image/jpeg
      let a = document.createElement('a')
      a.download = name // 设置下载的文件名
      a.href = u
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
      xhr.abort()
    }
  }
  xhr.send()
}

// 视频  yinseinner://hs_video?id=
// 短视频  yinseinner://hs_player_list?id=
//短视频 "yinseinner://hs_short_video"
// VIP  yinseinner://hs_member_vip
// 钱包 yinseinner://hs_member_wallet
// 直接播放页 yinseinner://hs_simple_player_page?url=/sp/av/3a4exxx.m3u8&w=1920&h=1080
// 分享邀请 yinseinner://hs_share_invite
// 全民代理 yinseinner://hs_member_agent
// 帖子详情 yinseinner://hs_post_detail?id=
// ai界面  yinseinner://hs_member_ai
// 套图站  yinseinner://hs_photo_station
// 美图主题  yinseinner://hs_photo_topic
// 女优搜索页  yinseinner://hs_actress_filter
// 播单详情  yinseinner://hs_playlist_center?id=
// 播单主题列表  yinseinner://hs_playlist_topic
// 首页搜索  yinseinner://hs_search?tabIndex=
// 女优/模特/网黄详情页  yinseinner://hs_model_center?actorId=
// 视频主题页 yinseinner://hs_video_topic_more?id=&title=
// 漫画主题页 yinseinner://hs_comics_topic_more?id=&title=
// 动漫主题页 yinseinner://hs_cartoon_topic_more?id=&title=
// path: `/acg/acgTopicDetail/`,
// query: {
//   id: id,
//   name: name,
//   type: 'cartoon'
// }
//自用新版跳转
export function JumpTo(url) {
  if (!url) return
  const jumpParam = {
    originUrl: url,
    urlParent: '',
    typeCode: ''
  }
  const str = url.split('?')
  jumpParam.urlParent = str[0]
  if (str.length && str.length > 1) {
    const q = str[1].split('&')
    for (let i = 0; i < q.length; i++) {
      jumpParam[q[i].split('=')[0]] = unescape(q[i].split('=')[1])
    }
  }
  console.log(jumpParam)
  switch (jumpParam.urlParent) {
    // 视频主题页跳转
    case 'yinseinner://hs_video_topic_more':
      router.push({
        path: '/home/videoTopicDetail',
        query: {
          id: jumpParam.id,
          name: jumpParam.title,
          showType: jumpParam.showType || ''
        }
      })
      break
    // 漫画主题页跳转
    case 'yinseinner://hs_comics_topic_more':
      router.push({ path: '/acg/acgTopicDetail', query: { id: jumpParam.id, name: jumpParam.title, type: 'comics' } })
      break
    // 动漫主题页跳转
    case 'yinseinner://hs_cartoon_topic_more':
      router.push({ path: '/acg/acgTopicDetail', query: { id: jumpParam.id, name: jumpParam.title, type: 'cartoon' } })
      break
    // 视频
    case 'yinseinner://hs_video':
      if (jumpParam.hasOwnProperty('id')) {
        router.push('/subpage/play/longVideo/' + Number(jumpParam.id)) // 存在视频id 则跳往播放页面
      } else {
        Toast('未获取到相应视频数据')
      }
      break
    // 短视频
    case 'yinseinner://hs_player_list':
      if (jumpParam.hasOwnProperty('id')) {
        router.push({ path: `/play/shortVideo`, query: { detailId: jumpParam.id } }) // 存在短视频id 则跳往短视频播放页面
      } else {
        Toast('未获取到相应视频数据')
      }
      return jumpParam
    // 短视频推荐列表
    case 'yinseinner://hs_short_video':
      router.push({ path: `/play/shortVideo`, query: { type: 'recommend' } })
      break
    // vip
    case 'yinseinner://hs_member_vip':
      router.push('/Mine/vip')
      break
    // 钱包
    case 'yinseinner://hs_member_wallet':
      // 直接跳转钱包页
      router.push('/mine/myWallet')
      break

    ////直接播放页
    case ' yinseinner://hs_simple_player_page':
      break

    // 分享
    case 'yinseinner://hs_share_invite':
      router.push('Mine/myShare')
      break
    // 全民代理
    case 'yinseinner://hs_member_agent':
      router.push({
        path: '/mine/myShouyi',
        query: { type: jumpParam.tabIndex || 0 }
      })
      break
    // 帖子详情
    case 'yinseinner://hs_post_detail':
      if (jumpParam.hasOwnProperty('id')) {
        router.push('/community/detail/' + Number(jumpParam.id))
      } else {
        Toast('未获取到相应帖子数据')
      }
      break
    // ai界面。
    case 'yinseinner://hs_member_ai':
      router.push('/ai/generate')
      break

    // 套图站
    case 'yinseinner://hs_photo_station':
      router.push('/discover/photo')
      break

    // 美图主题
    case 'yinseinner://hs_photo_topic':
      router.push('/discover/theme')
      break

    //女优搜索页
    case 'yinseinner://hs_actress_filter':
      router.push('/discover/girl')
      break

    // 播单详情
    case 'yinseinner://hs_playlist_center':
      router.push({
        path: '/discover/detail',
        query: {
          id: jumpParam.id.trim(),
          type: 1
        }
      })
      break
    // 播单主题列表
    case 'yinseinner://hs_playlist_topic':
      router.push('/discover/playlist')
      break

    //首页搜索
    case 'yinseinner://hs_search':
      router.push('/search')
      break

    //女优/模特/网黄详情页
    case 'yinseinner://hs_model_center':
      if (jumpParam.hasOwnProperty('actorId')) {
        router.push('/discover/actor/' + Number(jumpParam.actorId))
      }
      break
    //首页搜索
    case 'yinseinner://hs_find_rank':
      router.push('/discover/ranking')
      break
    default:
      //需要带token的活动
      if (jumpParam.hasOwnProperty('token')) {
        window.open(jumpParam.originUrl + store.state.user.token)
      } else {
        window.open(jumpParam.originUrl)
      }
      break
  }
}
